import React, { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { Button } from "react-bootstrap";

export const SignOutButton = () => {
    const { signOut } = useContext(UserContext)

    return (
        <div>
        <Button
            onClick={signOut}
         variant="outline-info" type="submit"className='mr-1 float'
            >
            Sign Out
        </Button>
    </div>
    )
};
