import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import {SearchAuthorisedPersonFilterDto} from "../../dto/SearchAuthorisedPersonFilterDto";
import {AuthorisedPersonSearchResultModelDto} from "../../dto/AuthorisedPersonSearchResultModelDto";

export interface AuthorisedPersonsState {
    visited: boolean;
    searchFilter: SearchAuthorisedPersonFilterDto;
    result: AuthorisedPersonSearchResultModelDto[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error?: string | null;
}
const initialState: AuthorisedPersonsState = {
    visited: false,
    searchFilter: {
        act: "", section: "", court: "", state: "", city: "", sections: [], citys: []
    },
    result: [],
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null 
}
interface SearchAuthorisedPersonFilterAndResultType {
   filter: SearchAuthorisedPersonFilterDto,
   result: AuthorisedPersonSearchResultModelDto[] 
}

export const authorisedPersonsSlice = createSlice({
    name: 'authorisedPersons',
    initialState,
    reducers: {
        saveAuthroisedPersonSearchFilterAndResult: (state, action: PayloadAction<SearchAuthorisedPersonFilterAndResultType>) => {
            state.searchFilter = action.payload.filter;
            state.result = action.payload.result;
            state.visited = true;
        },
        saveAuthroisedPersonSearchFilter: (state, action:PayloadAction<SearchAuthorisedPersonFilterDto>) => {
            state.searchFilter = action.payload;
            state.visited = true;
        },
        saveAuthroisedPersonSearchResult: ( state, action: PayloadAction<AuthorisedPersonSearchResultModelDto[]>) => {
            state.result = action.payload;
        },
        resetAuthroisedPersonSearchFilter: (state, action:PayloadAction<SearchAuthorisedPersonFilterDto>) => {
            state.searchFilter = action.payload;
            state.visited = false;
        }
    },
    
})

export const {saveAuthroisedPersonSearchFilter, saveAuthroisedPersonSearchResult , resetAuthroisedPersonSearchFilter, saveAuthroisedPersonSearchFilterAndResult} = authorisedPersonsSlice.actions;
export const selectAuthorisedPersonsFilter = (state: RootState) => state.authorisedPersons.searchFilter;
export const selectAuthorisedPersonsVisited = (state: RootState) => state.authorisedPersons.visited;
export const selectAuthorisedPersonsSearchResult = (state: RootState) => state.authorisedPersons.result;
export const selectAuthorisedPersonsSearchError = (state: RootState) => state.authorisedPersons.error;

export default authorisedPersonsSlice.reducer;