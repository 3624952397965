import React from 'react';


const SignIn = React.lazy(() => import('./views/SignIn'));


export const PublicRoutes = [
  { path: '/', exact: true, name: 'Signin', component: SignIn }
]

