import React, { useEffect } from 'react'
import { Row, Col, Pagination } from 'react-bootstrap';
import BTable from 'react-bootstrap/Table';
import { useTable, usePagination, useGlobalFilter, useSortBy, useRowSelect, useExpanded } from 'react-table'
const BasicTableControl = ({ columns, data, hideActionColumn, setSelectedRows, renderRowSubComponent }) => {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow,
        visibleColumns,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        state,
        setPageSize,
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                hiddenColumns: hideActionColumn ? ["actions"] : [],
            },
        },
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
    );

    const { pageIndex } = state;
    // Render the UI for your table

    useEffect(() => {
        setPageSize(10)
    }, [setPageSize]);

    useEffect(() => {
        setSelectedRows(selectedFlatRows.map(row => row.original));
      }, [setSelectedRows, selectedFlatRows]);

    return (
        <>
            <BTable striped bordered hover responsive {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column,index) => (
                                <th key={index.toString() + "column"}{...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>

                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, index) => {
                        prepareRow(row)
                        return (
                            <React.Fragment key={"Fragment" + index.toString()}>
                            <tr key={index.toString()} {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps({
                                        style: {
                                            minWidth: cell.column.minWidth,
                                            width: cell.column.width,
                                        },
                                    })}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                                            {row.isExpanded ? (
                                                <tr key={index.toString() + "expanded"}>
                                                  <td colSpan={visibleColumns.length}>
                                                    {/*
                                                        Inside it, call our renderRowSubComponent function. In reality,
                                                        you could pass whatever you want as props to
                                                        a component like this, including the entire
                                                        table instance. But for this example, we'll just
                                                        pass the row
                                                      */}
                                                    {renderRowSubComponent({ row })}
                                                  </td>
                                                </tr>
                                              ) : null}
                            </React.Fragment>
                        )
                    })}
                </tbody>
            </BTable>
            <Row className='justify-content-between mt-3'>
                <Col sm={12} md={6}>
                    <span className="d-flex align-items-center">
                        Page &nbsp; <strong> {pageOptions.length === 0 ? 0 : pageIndex + 1} of {pageOptions.length} </strong> &nbsp;
                        {pageOptions.length === 0 ? '' : '| Go to page: '}
                        {pageOptions.length !== 0 && <input
                            type="number"
                            className='form-control ml-2'
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(page)
                            }}
                            style={{ width: '100px' }}
                        /> }
                    </span>
                </Col>
                <Col sm={12} md={3}>
                    <Pagination className='justify-content-end'>
                        <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                        <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
                        <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
                        <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
                    </Pagination>
                </Col>
            </Row>
        </>
    )
}
 
export default BasicTableControl;