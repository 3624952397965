import * as React from "react";
import {useEffect, useState} from "react";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import PerfectScrollbar from 'react-perfect-scrollbar';
import BasicTableControl from '../../controls/BasicTableControl.jsx';
import * as constants from '../../utils/constants';
import {useNavigate, useLocation} from "react-router-dom";
import {useFetchDataOnLoad} from '../../hooks'
import {PersonSearchResultModelDto} from "../../dto/PersonSearchResultModelDto";
import {searchAppointmentData} from "../../services/persondetailsdata";
import {PersonDetailsDto} from "dto/PersonDetailsDto";
import {UserContext} from "contexts/UserContext.jsx";
import PersonInfoComponent from "./PersonInfoComponent";
import ConfirmModal from "components/confirmModal.js";
import {errorToast} from "utils/toast.js";
import {removeAppointment} from 'services/persondetailsdata';
import {personsApi} from "../../app/services/personApi";
import {useAppDispatch} from "../../app/store";

interface PersonFormComponentProps {
    personDetails: PersonDetailsDto;
    readonly: boolean;
    handleCancel: () => void
    handleSubmit: (values: any) => void
}

const PersonFormComponent = (props: PersonFormComponentProps) => {

    const {checkFeatureAccess, user} = React.useContext(UserContext);

    function useQuery() {
        const {search} = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    const personId = (query.get("personId"));

    //const {result: person} = useFetchDataOnLoad(() => searchPersonData(props.personId), []);
    //const [searchResult, setSearchResult] = useState<PersonDetailsDto>(person);

    const {result: appointments} = useFetchDataOnLoad(() => searchAppointmentData(personId), []);
    const [appointmentResult, setappointmentResult] = useState<PersonSearchResultModelDto[]>(appointments);
    const canEditAppointment = checkFeatureAccess(user, "Edit Appointment");

    const history = useNavigate();

    const [selectedRows, setSelectedRows] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const dispatch = useAppDispatch();
    
    useEffect(() => {
        setappointmentResult(appointments)
        return () => {
            setappointmentResult([]);
        }
    }, [appointments]);

    const Routepagenew = () => {
        history('/persondetails/editpersondetails/addappointment?personId=' + personId);
    }

    const onSubmit = async (values: PersonDetailsDto) => {
        if (values) {
            props.handleSubmit(values);
        }
    }

    const onRemoveAppointment = async () => {
        var appointmentIds = selectedRows.map((x: any) => x.commissionId);
        console.log(appointmentIds)
        await removeAppointment(appointmentIds, props.personDetails.personId).then((result: any) => {
            if (result != null) {
                setModalShow(false);
                setSelectedRows([]);
                dispatch(personsApi.util.invalidateTags(['AuthorisedPersons']));
                dispatch(personsApi.util.invalidateTags(['PersonDetails']));
                setappointmentResult(result);

            }
            ;
        }).catch((err: any) => {
            console.log("err -> ", JSON.stringify(err));
            errorToast('Failed to remove appointment(s).')
        }).finally(() => {
        });
    }


    return (
        <React.Fragment>
            <>
                <Container fluid="md">
                    {/* {searchResult &&  */}
                    <PersonInfoComponent personDetails={props.personDetails} readonly={props.readonly}
                                         handleCancel={() => {
                                         }}
                                         handleSubmit={onSubmit}/>
                    {/* } */}
                    <br/>
                    <Row>
                        <Col>
                            <Card className='table-card'>

                                <Card.Header>
                                    <Card.Title as="h5"> Appointment
                                        {checkFeatureAccess(user, 'Remove Appointment') && selectedRows.length > 0 &&
                                            <Button variant="outline-info"
                                                    onClick={() => setModalShow(true)}
                                                    className='mr-1 float-right'>Remove
                                                Appointment</Button>}
                                        {checkFeatureAccess(user, 'Add Appointment') &&
                                            <Button variant="outline-info"
                                                    onClick={() => {
                                                        Routepagenew();
                                                    }}
                                                    className='mr-1 float-right'>Add New
                                                Appointment</Button>}
                                    </Card.Title>

                                </Card.Header>
                                {appointmentResult &&
                                    <Card.Body className='pb-0'>
                                        <div style={{height: '100%'}}>
                                            <PerfectScrollbar>
                                                <BasicTableControl key={"person-appointment-list"}
                                                                   columns={canEditAppointment ?
                                                                       constants.allAppointmentdetailsColumns
                                                                       : constants.allAppointmentdetailsColumnsToViewOnly}
                                                                   hideActionColumn={false}
                                                                   data={appointmentResult}
                                                                   setSelectedRows={setSelectedRows}
                                                                   renderRowSubComponent={() => {
                                                                   }}/>
                                            </PerfectScrollbar>
                                        </div>
                                        {/* <div>
                                    <Button variant="outline-info"  onClick={() => {Routepageold()}}
                                            className='mr-1 float-right'>Back</Button>
                                </div> */}
                                        <br/>
                                        <br/>
                                    </Card.Body>
                                }
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    heading="Remove Appointment(s)"
                    body="Are you sure you want to remove the selected appointment(s)?"
                    onDelete={onRemoveAppointment}
                />
            </>
        </React.Fragment>
    );
}
export default PersonFormComponent;