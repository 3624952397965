import React from 'react';
import './components/scss/style.scss'
import { UserContextProvider, UserContext } from './contexts/UserContext';
import UserNotFound from './views/UserNotFound';
import Spinner from './components/Loader/Spinner';
import { SystemContext, SystemContextProvider } from './contexts/SystemContext'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, BrowserRouter as Router } from 'react-router-dom';
import { PublicRouter } from './PublicRouter'
import routes, { AppRoutes } from "./routes";
import { BASENAME } from "./config/constant";
import { Button, Modal } from 'react-bootstrap';

const App = () => {


  return (
<>
    <UserContextProvider>
      <UserContext.Consumer>
        {({ isAuthLoading, userError, user, showIdleWarning, extendSession }) =>
          // Display loading indicator while user is being authenticated
          isAuthLoading ?
            <Spinner />
            : userError ?
              <UserNotFound />
              : user ?
                <SystemContextProvider>
                  <SystemContext.Consumer>
                    {
                      ({ fetchingReferenceData }) => fetchingReferenceData ? <Spinner />
                        :
                        <>
                          <ToastContainer />

                          <Router basename={BASENAME}>
                            <AppRoutes routes={routes} />
                          </Router>

                          <Modal show={showIdleWarning} centered>
                            <Modal.Header>
                              <Modal.Title id="modal-heading">Your session is ending soon</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <p>Your session is ending soon and you will be signed out.</p>
                              <p>Please interact with the application to stay logged in.</p>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button onClick={extendSession} >Extend session</Button>
                            </Modal.Footer>
                          </Modal>


                        </>

                    }
                  </SystemContext.Consumer>
                </SystemContextProvider>
                :
                <BrowserRouter>
                  <PublicRouter />
                </BrowserRouter>

        }
      </UserContext.Consumer>
      </UserContextProvider>
      </>

  );



};
export default App;
