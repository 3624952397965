import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

function Input (props) {
    const { label, name, inputRequired, ...rest } = props
  return (
      <div className='form-ctrl'>
          <label htmlFor={name} className={inputRequired ? "control-required" : ""}>{label}</label>
          <Field className='form-control' id={name} name={name} placeholder={label}  {...rest} />
      <ErrorMessage component={TextError} name={name} />
    </div>
  )
}

export default Input
