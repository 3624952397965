import React, { createContext } from 'react'
import { useFetchDataOnLoad } from '../hooks/useFetchDataOnLoad'
import {
    //getActs, getSectionsByActId
    getActs,
    getCourtTribunallist,
    getStates,
  
}
    from '../services/referenceData'

export const SystemContext = createContext()

export const SystemContextProvider = ({ children }) => {
    const { fetching: getAllActs, result: acts } = useFetchDataOnLoad(() => getActs(), []);
    //const {actId}  = children;

    //const { result: acts, refresh: refreshActs } = useFetchDataOnLoad(() => getActs(), []);
  //  const { result: sections, refresh: refreshSections } = useFetchDataOnLoad(() => getSectionsByActId(actId), []);
    //const { result: acts } = useFetchDataOnLoad(() => getActs(), []);
    const { result: courts } = useFetchDataOnLoad(() => getCourtTribunallist(), []);
    const { result: states } = useFetchDataOnLoad(() => getStates(), []);

    const systemContextValue = {
         getAllActs,
         acts,
        courts,
        states
        // acts,
        // refreshActs,

       // sections,
      //  refreshSections
    }

  return <SystemContext.Provider value={systemContextValue}>{ children }</SystemContext.Provider>
}
