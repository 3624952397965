import api from './apiClient'


export const  searchRegistriesData = async (data) => {
    return api.get('GetActiveRegistries?courttribunalid='+data.court+'&cityid='+data.city+'&stateid='+data.state)
}


export const  searchRegistryData = async (registryId) => {
    return api.get('GetRegistryById?registryId='+ registryId)
}


export const createOrUpdateRegistryDetails = async (data) => {
    
    return api.post('createRegistry', data);
}


export const createContactDetails = async (data) => {
    
    return api.post('createContact', data);
}

export const updateRegistryAddress = async (data) => {
    
    return api.post('updateRegistryAddress', data);
}

export const updateRegistryCommission = async (data) => {
    
    return api.post('updateRegistryCommission', data);
}



