import * as React from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {AppointmentDto} from "../../dto/AppointmentDto";
import {IApiAction} from "../../Interfaces";
import {PersonSearchResultModelDto} from "../../dto/PersonSearchResultModelDto";
import api from "../../services/apiClient";
import {personsApi} from "../../app/services/personApi";
import moment from "moment";
import {createAppointmentDetails} from "../../services/persondetailsdata";
import {errorToast} from "../../utils/toast";
import AppointmentFormComponent from "./AppointmentFormComponent";
import {useEffect, useState} from "react";
import {saveCurrentBreadcrumbs} from "../../app/navigationSlice";
import {BreadcrumbType} from "../../components/AppBreadcrumb";
import {useAppDispatch} from "../../app/store";

const newAppointment: AppointmentDto = {
    commissionId: 0, createdDate: "", createdId: "",
    court: "", state: "", city: "", startdate: "", enddate: "", position: "",
    endreason: "", employmenttype: "", termtype: "", personId: "", citys: [], registries: []
}

interface AppointmentAddState extends IApiAction<AppointmentDto> {
    hasError: boolean;
    person?: PersonSearchResultModelDto;
}

const AppointmentAddView = () => {

    const breadcrumbs : BreadcrumbType[] = [];
    function useQuery() {
        const {search} = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    const personId: string = (query.get("personId")) + "";
    const [state, setState] = useState<AppointmentAddState>({hasError:false, entity: newAppointment});

    const dispatch = useAppDispatch();

    useEffect(() => {
        const fetchData = async () => {
            
            // @ts-ignore
            const data = await api.get('GetPersonById?personId=' + personId)
            if (data) {
                setState({...state, person: data})
                breadcrumbs.push({
                    active: false,
                    name: "Person Details",
                    pathname: "/persondetails"
                })
                breadcrumbs.push({
                    active: false,
                    name: "Edit Person",
                    pathname: "/persondetails/editpersondetails?personId=" + personId
                })
                breadcrumbs.push({
                    active: true,
                    name: "Add Appointment",
                    pathname: "/persondetails/editpersondetails/addappointment?personId=" + personId
                })
                dispatch(saveCurrentBreadcrumbs(breadcrumbs));
            }
        }
        fetchData();
    }, [])
    const history = useNavigate();

    const Routepageback = (personId: string) => {
        //console.log('route' + personId)
        history('/persondetails/editpersondetails?personId=' + personId);
    };

    // @ts-ignore
    const onSubmit = async (values: AppointmentDto) => {
        //console.log(employmentTypes);
        values.startdate = moment(values.startdate).format("YYYY-MM-DD")
        values.enddate = values.enddate ? moment(values.enddate).format("YYYY-MM-DD") : null
        values.personId = personId;
        //console.log(values);
        await createAppointmentDetails(values).then((result) => {
            if (result !== 0) {
                // console.log(result);
                // console.log('commissionId' + result.commissionId)
                dispatch(personsApi.util.invalidateTags(['AuthorisedPersons']));
                dispatch(personsApi.util.invalidateTags(['PersonDetails'])); 
                Routepageback(result.personId);
            }

        }).catch((err) => {
            console.log("err -> ", JSON.stringify(err));
            errorToast('Failed to get Id.')
        }).finally(() => {

        });
    };

        return <AppointmentFormComponent
            entity={newAppointment} personId={personId}
            handleCancel={()=>{
               history(-1); 
            }}
            handleIsDirty={()=>{}}
            handleIsValid={()=>{}}
            handleSubmit={onSubmit} readonly={false}></AppointmentFormComponent>


}
export default AppointmentAddView;