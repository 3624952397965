import React, {useMemo, useCallback} from 'react';
import {useDropzone} from 'react-dropzone';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const maxSize = 2000000;

function fileValidator(file) {
  if (file.size > maxSize) {
    return {
      code: "file-too-large",
      message: `File is larger than 2MB`
    };
  }
  return null
}

function DropZone(props) {

  const [files, setFiles] = React.useState([]);
  let formData = new FormData()

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
    acceptedFiles.forEach((file) => {
      formData.append('assets', file, file.name)
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        //reader.append('assets', file, file.name)
      // Do whatever you want with the file contents
        const binaryStr = reader.result
        props.handleFileUpload(binaryStr, file);
      }
      reader.readAsArrayBuffer(file)
    })
  }, [files])

  const {getRootProps, getInputProps, isFocused, fileRejections} = useDropzone({onDrop, 
    multiple: false,
    accept: {
      'application/pdf': [],
    },
    validator: fileValidator
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
  }), [
    isFocused,
  ]);

  const acceptedFileItems = files.map(file =>
    (<li key={file.path}>
    {file.path} - {file.size} bytes
  </li>)
  );

  const fileRejectionItems = fileRejections.map(({ file, errors  }) => { 
   return (
     <li key={file.path}>
          {file.path} - {file.size} bytes
          <ul>
            {errors.map(e => <li key={e.code} style={{color:"red"}}>{e.message}</li>)}
         </ul>

     </li>
   ) 
  });

  return (
    <section className="container px-0 py-2">
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p>Drag and drop a file here, or click to select a file</p>
        <em>(Upload of  one PDF file at a time is supported)</em>
      </div>
      <aside>
        {files.length > 0 && 
          <div>
            <h6 className="pt-2">Accepted file</h6>
            <ul>{acceptedFileItems}</ul>
          </div>
        }
        {fileRejections.length > 0 &&
          <div>
            <h6 className="pt-2">Rejected file</h6>
            <ul>{fileRejectionItems}</ul>
          </div>
        }
      </aside>
    </section>
  );
}

export default DropZone