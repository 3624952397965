import {api} from './api'
import {ActSearchResultModelDto} from "../../dto/ActSearchResultModelDto";
import {SectionSearchResultModelDto} from "../../dto/SectionSearchResultModelDto";
import {SectionCreateRequestModelDto} from "../../dto/SectionCreateRequestModelDto";
import { SectionDto } from 'dto/SectionDto';
import {CommandResponseModelDto} from "../../dto/CommandResponseModelDto";

type ActsResponse = ActSearchResultModelDto[];

export const actsApi = api.injectEndpoints({
    endpoints: (build) => ({

        getActs: build.query<ActsResponse, void>({
            query: () => ( {
                    url: 'getActs',
                    method: 'GET',
                    headers: {
                        'accept':'application/json',
                        'content-type':'application/json; charset=utf-8'
                    }} ),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ actId }) => ({ type: 'Acts' as const, id: actId })),
                        { type: 'Acts', id: 'LIST' },
                    ]
                    : [{ type: 'Acts', id: 'LIST' }],
        }),
        getActiveActs: build.query<ActsResponse, void>({
            query: () => ( {
                url: 'getActiveActs',
                method: 'GET',
                headers: {
                    'accept':'application/json',
                    'content-type':'application/json; charset=utf-8'
                }} ),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ actId }) => ({ type: 'Acts' as const, id: actId })),
                        { type: 'Acts', id: 'LIST' },
                    ]
                    : [{ type: 'Acts', id: 'LIST' }],
        }),
        getAct: build.query<ActSearchResultModelDto, string>({
            query: (id) => ({
                url: 'getActById?actId=' + id,
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'content-type': 'application/json; charset=utf-8'
                }
            }),
            providesTags: (_result, _err, id) => [{type: 'Acts', id}],
        }),
        // getActWithActiveSectionById
        getActiveAct: build.query<ActSearchResultModelDto, string>({
            query: (id) => ({
                url: 'getActWithActiveSectionById?actId=' + id,
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'content-type': 'application/json; charset=utf-8'
                }
            }),
            providesTags: (_result, _err, id) => [{type: 'Acts', id}],
        }),
        // export const removeSections = async (ids) => {
        //     return api.post('removeSections', ids)
        // }
        // RemoveActsValidate 
        removeActsValidate: build.mutation<CommandResponseModelDto, number[]>({
            query: (body) => ({
                url: 'RemoveActsValidate',
                method: 'POST',
               body,
                invalidatesTags: ['Acts'],
            }),
        }),
        removeActs: build.mutation<CommandResponseModelDto, number[]>({
            query: (body) => ({
                url: 'removeActs',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Acts'],
        }),

        getSection: build.query<SectionDto, string>({
            query: (id) => ({
                url: 'getSectionById?sectionId=' + id,
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'content-type': 'application/json; charset=utf-8'
                }
            }),
            providesTags: (_result, _err, id) => [{type: 'Sections', id}],
        }),
        addSection: build.mutation<number, SectionCreateRequestModelDto >({
            query: (body) => ({
                url: 'CreateSection',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Acts'],
        }),
        // return api.get('getSections?actId=' + id)
        // return api.post('removeSections', ids)
        removeSectionsValidate: build.mutation<CommandResponseModelDto, number[]>({
            query: (body) => ({
                url: 'RemoveSectionsValidate',
                method: 'POST',
                body,
                invalidatesTags: ['Sections'],
            }),
        }),
        removeSection: build.mutation<boolean, number[]>({
            query: (body) => ({
                url: 'removeSections',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Acts'],
        }),
        updateSection: build.mutation<number, SectionDto >({
            query: (body) => ({
                url: 'updateSection',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Acts'],
        }),
        getSections: build.query<SectionSearchResultModelDto[], void>({
            query: (id) => ( {
                url: 'getSections?actId=' + id,
                method: 'GET',
                headers: {
                    'accept':'application/json',
                    'content-type':'application/json; charset=utf-8'
                }} ),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ sectionId }) => ({ type: 'Sections' as const, id: sectionId })),
                        { type: 'Sections', id: 'LIST' },
                    ]
                    : [{ type: 'Sections', id: 'LIST' }],
        }),
        getActiveSections: build.query<SectionSearchResultModelDto[], string>({
            query: (id) => ( {
                url: 'getActiveSections?actId=' + id,
                method: 'GET',
                headers: {
                    'accept':'application/json',
                    'content-type':'application/json; charset=utf-8'
                }} ),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ sectionId }) => ({ type: 'Sections' as const, id: sectionId })),
                        { type: 'Sections', id: 'LIST' },
                    ]
                    : [{ type: 'Sections', id: 'LIST' }],
        }),

        getErrorProne: build.query<{ success: boolean }, void>({
            query: () => 'error-prone',
          }),
    })
})

export const {
    useGetActsQuery,
    useLazyGetActiveActsQuery,
    useGetActQuery,
    useLazyGetActQuery,
    useGetActiveActQuery,
    useGetSectionQuery,
    useLazyGetSectionQuery,
    useGetSectionsQuery,
    useLazyGetSectionsQuery,
    useLazyGetActiveSectionsQuery,
    useAddSectionMutation,
    useRemoveActsValidateMutation,
    useRemoveActsMutation,
    useRemoveSectionsValidateMutation,
    useRemoveSectionMutation,
    useUpdateSectionMutation
} = actsApi

export const { 
    endpoints: {getActs},
    } = actsApi

