import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

function RadioButtons (props) {
    const { label, name, options, inputRequired, ...rest } = props
  return (
      <div className='form-ctrl'>
      <label htmlFor={name} className={inputRequired ? "control-required" : ""}>{label}</label>
      <div>
      <Field name={name} >
        {({ field }) => {
          return options.map(option => {
            return (
              <React.Fragment key={option.name}>
                <div style={{display: 'inline'}}>
                  <input
                    type='radio'
                    id={name + option.id}
                    {...field}
                    {...rest}
                    value={option.value}
                    checked={field.value + '' === option.value + ''}
                    className="mr-2"
                  />
                  <label htmlFor={name + option.id} className="pr-3">{option.name}</label>
                </div>
              </React.Fragment>
            )
          })
        }}
      </Field>
      </div>
      <ErrorMessage component={TextError} name={name} />
    </div>
  )
}

export default RadioButtons
