import * as React from 'react';
import {IApiAction} from "../../Interfaces";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {Form, Formik} from "formik";
import * as Yup from 'yup';
import FormikControl from "../../components/FormikControl";
import {RegistryDto} from "../../dto/RegistryDto";
import {OptionItem} from "../../dto/OptionItem";
import {ContactTypeDto} from "../../dto/ContactTypeDto";
import {IBaseFormPropsFunctions} from "../appointments/AppointmentFormComponent";
import {useEffect, useState} from "react";
import {useLazyGetContactTypesQuery, useLazyGetStatesQuery} from "../../app/services/RefApi";
import {RouterPrompt} from "../../controls/RouterPrompt";

interface RegistryFormState extends IApiAction<RegistryDto> {
    fetching: boolean;
    states?: OptionItem[];
    contacttypes?: ContactTypeDto[];
    show?: boolean;
}

const initialValues: RegistryDto = {
    registryId: 0, contactTypeId: "", city: "", courtTribunal: "", state: "", line1: "", line2: "", line3: "",
    namePositionTitle: "", phoneNumber: "", postCode: "", court_State: "", court_City: "", sortOrder: ""
}

function RegistryFormComponent(props: IBaseFormPropsFunctions<RegistryDto>) {
    const [state, setState] = useState<RegistryFormState>(
        {entity: initialValues, fetching: false, show: false}
    );
    
    const [getStates] = useLazyGetStatesQuery();
    const [getContactTypes] = useLazyGetContactTypesQuery();
    useEffect(() => {
        const fetchData = async () => {
            // @ts-ignore
            const states = await getStates().unwrap();
            const stateList: OptionItem[] = states == null ? [] : states.map((item: any) => ({
                id: item.stateId, name: item.code
            }));
            const contacttypes = await getContactTypes().unwrap();
            setState({...state, states: stateList, contacttypes: contacttypes});
        };
        fetchData();
    }, []);

    // @ts-ignore
    const handleSortOrder = (e, setFieldValue) => {
        const re = /^[0-9]*$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setFieldValue('sortOrder', e.target.value)
        }
    }
    // @ts-ignore
    const handlePhoneNumber = (e, setFieldValue) => {
        const re = /^[0-9) ]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setFieldValue('phoneNumber', e.target.value)
        }
    }

    // @ts-ignore
    const handlePostCode = (e, setFieldValue) => {
        const re = /^[0-9]*$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setFieldValue('postCode', e.target.value)
        }
    }

    // @ts-ignore
    const handleNameAndPositionTitle = (e, setFieldValue) => {
        const re = /^[a-zA-Z ']*$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setFieldValue('namePositionTitle', e.target.value)
        }
    }
//const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const phoneRegExp = /^[0-9) ]+$/; ///^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;

    const onSubmit = async (values: RegistryDto, actions: any) => {
        props.handleSubmit(values);
        actions.setSubmitting(false);
        actions.resetForm({
            values: props.entity,
        });
    }

    // @ts-ignore
    const validationSchema = (props: any) =>
        Yup.lazy((values: any) =>
            Yup.object({
                courtTribunal: Yup.string().trim().max(100, 'CourtTribunal is too Long')
                    //.matches(/^[aA-zZ\s]+$/, 'Please enter valid Court/Tribunal')
                    .required('This is a required field'),
                court_State: Yup.string().trim().required('This is a required field'),
                court_City: Yup.string().trim().max(100, 'City is too Long').matches(/^[ A-Za-z_@./&\-()]*$/, 'Please enter valid City').required('This is a required field'),
                postCode: Yup.string().trim().max(4, 'Postcode is incorrect').matches(/^[0-9\s]+$/, 'Please enter valid Postcode').required('This is a required field'),
                state: Yup.string().trim().required('This is a required field'),
                city: Yup.string().trim().max(100, 'City is too Long').matches(/^[ A-Za-z_@./&\-()]*$/, 'Please enter valid City').required('This is a required field'),
                line1: Yup.string().trim().max(400, 'Line 1 is too Long').matches(/^[ A-Za-z0-9_@./&\-()]*$/, 'Please enter valid Address').required('This is a required field'),
                line2: Yup.string().trim().max(400, 'Line 2 is too Long').matches(/^[ A-Za-z0-9_@./&\-()]*$/, 'Please enter valid Address'),
                line3: Yup.string().trim().max(400, 'Line 3 is too Long').matches(/^[ A-Za-z0-9_@./&\-()]*$/, 'Please enter valid Address'),
                contactTypeId: Yup.number().typeError('This is a required field').required('This is a required field'),
                namePositionTitle: Yup.string().trim().max(200, 'Contact is too Long').matches(/^[a-zA-Z ']*$/, 'Please enter valid Contact').required('This is a required field'),
                phoneNumber: Yup.string().trim().max(14, 'Phone number is not valid').matches(phoneRegExp, 'Phone number is not valid').required('This is a required field'),
                sortOrder: Yup.number()
                    .required('This is a required field')
                    .test(
                        'Is positive?',
                        'Please enter number between 1 and 99!',
                        // @ts-ignore
                        (value) => 100 > value > 0
                    ),
            }))

    return <React.Fragment><>
        <Container fluid="md">
            <Formik enableReinitialize={true} initialValues={props.entity!} onSubmit={onSubmit}
                    validationSchema={validationSchema}>
                {
                    formik => {
                        const {
                            values, handleChange, setFieldValue,
                            resetForm,
                            dirty,
                            isValid
                        } = formik;
                        // @ts-ignore
                        return (
                            <Form placeholder={undefined}>
                                {/*<RouterPrompt
                                    when={dirty && (props.entity?.registryId !== 0)}
                                    title="Unsaved changes"
                                    cancelText="Close"
                                    okText="Discard"
                                    onOK={() => true}
                                    onCancel={() => false}
                                />*/} {/*<PromptIfDirty/>*/}
                                <fieldset disabled={props.readonly}>
                                    <Card className="text-left">
                                        <Card.Header>
                                            <Card.Title>Registry Details:</Card.Title>
                                        </Card.Header>
                                        <Card.Body className='pb-0'>
                                            <Row>
                                                <Col sm={4}>
                                                    <FormikControl control='input' type='text' inputRequired
                                                                   label='Court or Tribunal' name='courtTribunal'
                                                                   value={values.courtTribunal}/>
                                                </Col>

                                                <Col sm={4}>
                                                    {
                                                        <FormikControl control='select' label='State or Territory'
                                                                       onChange={handleChange} inputRequired
                                                                       value={values.court_State} name='court_State'
                                                                       options={state.states?.map((item: any) => ({
                                                                           id: item.id,
                                                                           name: item.name
                                                                       }))}/>
                                                    }
                                                </Col>
                                                <Col sm={4}>
                                                    <FormikControl control='input' type='text' inputRequired
                                                                   label='City' name='court_City'
                                                                   value={values.court_City}/>
                                                </Col>
                                            </Row>
                                            <br/>
                                        </Card.Body>
                                    </Card>
                                    <Card className="text-left">
                                        <Card.Header>
                                            <Card.Title>Address:</Card.Title>
                                        </Card.Header>
                                        <Card.Body className='pb-0'>
                                            <Row>
                                                <Col sm={6}>
                                                    <FormikControl control='input' type='text' inputRequired
                                                                   label='Line 1' name='line1' value={values.line1}/>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row>
                                                <Col sm={6}>
                                                    <FormikControl control='input' type='text' label='Line 2'
                                                                   name='line2' value={values.line2}/>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row>
                                                <Col sm={6}>

                                                    <FormikControl control='input' type='text' label='Line 3'
                                                                   name='line3' value={values.line3}/>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row>
                                                <Col sm={4}>
                                                    <FormikControl control='input' type='text' inputRequired
                                                                   label='City' name='city' value={values.city}/>
                                                </Col>
                                                <Col sm={4}>
                                                    {
                                                        <FormikControl control='select' label='State or Territory'
                                                                       onChange={handleChange} value={values.state}
                                                                       name='state' inputRequired
                                                                       options={state.states?.map(item => ({
                                                                           id: item.id,
                                                                           name: item.name
                                                                       }))}/>
                                                    }
                                                </Col>
                                                <Col sm={4}>

                                                    <FormikControl control='input' type='text' label='Post Code'
                                                                   name='postCode' inputRequired value={values.postCode}
                                                                   onChange={(e: any) => handlePostCode(e, setFieldValue)}/>
                                                </Col>
                                            </Row>
                                            <br/>
                                        </Card.Body>
                                    </Card>
                                    <Card className="text-left">
                                        <Card.Header>
                                            <Card.Title>Phone Number:</Card.Title>
                                        </Card.Header>
                                        <Card.Body className='pb-0'>
                                            <Row>
                                                <Col sm={3}>

                                                    <FormikControl control='input' type='text' label='Contact'
                                                                   inputRequired name='namePositionTitle'
                                                                   value={values.namePositionTitle}
                                                                   onChange={(e: any) => handleNameAndPositionTitle(e, setFieldValue)}/>
                                                </Col>
                                                <Col sm={3}>

                                                    <FormikControl control='input' type='text' inputRequired
                                                                   label='Phone Number' name='phoneNumber'
                                                                   value={values.phoneNumber}
                                                                   onChange={(e: any) => handlePhoneNumber(e, setFieldValue)}
                                                                   maxLength={14}/>
                                                </Col>
                                                <Col sm={3}>

                                                    {
                                                        <FormikControl control='select' label='Contact Type'
                                                                       inputRequired onChange={handleChange}
                                                                       value={values.contactTypeId} name='contactTypeId'
                                                                       options={(state.contacttypes?.map((item: ContactTypeDto) => ({
                                                                           id: item.contactTypeId,
                                                                           name: item.description
                                                                       })))}/>
                                                    }

                                                </Col>
                                                <Col sm={3}>
                                                    <FormikControl control='input' type='text' inputRequired
                                                                   label='Sort Order' name='sortOrder'
                                                                   value={values.sortOrder}
                                                                   onChange={(e: any) => handleSortOrder(e, setFieldValue)}
                                                                   maxLength={2}/>
                                                </Col>
                                            </Row>
                                            <br/>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body className='pb-0'>


                                            <Row>
                                                <Col>
                                                    <Button variant="outline-info" type='submit'
                                                            hidden={props.readonly}
                                                            disabled={props.readonly || (!(dirty && isValid))}
                                                            className='mr-1 float-right'>Save</Button>
                                                    <Button variant="outline-info"
                                                            hidden={true}
                                                            onClick={() => {
                                                                if (values.registryId === 0) {
                                                                    props.handleCancel()
                                                                } else {
                                                                    resetForm();
                                                                }
                                                            }} disabled={props.readonly || (!(dirty))}
                                                            className='mr-2 float-right'>Cancel</Button>
                                                </Col>
                                            </Row>
                                            <br/>


                                        </Card.Body>
                                    </Card>
                                </fieldset>
                            </Form>
                        )
                    }
                }
            </Formik>
            <Row>
            </Row>
        </Container>
    </>
    </React.Fragment>
        ;
}

export default RegistryFormComponent;