import {api} from './api'
import {SearchPersonFilterDto} from "../../dto/SearchPersonFilterDto";
import {SearchPersonResultDto} from "../../dto/SearchPersonResultDto";
import {PersonSearchResultModelDto} from "../../dto/PersonSearchResultModelDto";
import {PersonDetailsDto} from 'dto/PersonDetailsDto';
import {AuthorisedPersonSearchResultModelDto} from "../../dto/AuthorisedPersonSearchResultModelDto";
import {SearchAuthorisedPersonFilterDto} from "../../dto/SearchAuthorisedPersonFilterDto";
import {AuthorisedPersonSearchCriteriaModelDto} from "../../dto/AuthorisedPersonSearchCriteriaModelDto";
import {PersonSearchCriteriaModelDto} from "../../dto/PersonSearchCriteriaModelDto";

type PersonsResponse = SearchPersonResultDto[];

export const personsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAuthorisedPersons: build.query<AuthorisedPersonSearchResultModelDto[], SearchAuthorisedPersonFilterDto>({
            query: (data) => ({
                    url: 'getauthorisedperson?actId=' + data.act + '&sectionId=' + data.section + '&courttribunalid=' + data.court + '&cityid=' + data.city + '&stateid=' + data.state,
                    method: 'GET',
                    headers: {
                        'accept': 'application/json',
                        'content-type': 'application/json; charset=utf-8'
                    }
                }
            ),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({commissionId}) => ({type: 'AuthorisedPersons' as const, id: commissionId})),
                        {type: 'AuthorisedPersons', id: 'LIST'},
                    ]
                    : [{type: 'AuthorisedPersons', id: 'LIST'}],
        }),
        //
        searchAuthorisedPerson: build.mutation<AuthorisedPersonSearchResultModelDto[], AuthorisedPersonSearchCriteriaModelDto>({
            query: (body) => ({
                url: 'searchAuthorisedPerson',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['AuthorisedPersons'],
        }),

        searchPersonDetails: build.mutation<PersonsResponse, PersonSearchCriteriaModelDto>({
            query: (body) => ({
                    url: 'GetPersons',
                    method: 'POST',
                    body
                }
            ),
            invalidatesTags:['PersonDetails']
        }),
        // the following is from search person from person detail screen
        getPersonDetails: build.query<PersonsResponse, SearchPersonFilterDto>({
            query: (data) => ({
                    url: 'GetPersons?firstName=' + data.firstName + '&surname=' + data.surname + '&searchActId=' + data.act + '&searchSectionId=' + data.section + '&courttribunalid=' + data.court + '&cityid=' + data.city + '&stateid=' + data.state + '&appointmentStatusId=' + data.appointmentStatusId + '&commissionStatusId=' + data.commissionStatusId,
                    method: 'GET',
                    headers: {
                        'accept': 'application/json',
                        'content-type': 'application/json; charset=utf-8'
                    }
                }
            ),

            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({personId}) => ({type: 'PersonDetails' as const, id: personId})),
                        {type: 'PersonDetails', id: 'LIST'},
                    ]
                    : [{type: 'PersonDetails', id: 'LIST'}],
        }),
        // used for search person for appoitnment
        getPerson: build.query<PersonSearchResultModelDto, string>({
            query: (id) => ({
                url: 'GetPersonById?personId=' + id,
                method: 'GET',
            }),
            providesTags: (_result, _err, id) => [{type: 'Persons', id}],
        }),
        getErrorProne: build.query<{ success: boolean }, void>({
            query: () => 'error-prone',
        }),

        // Update person details
        updatePerson: build.mutation<PersonDetailsDto, Partial<PersonDetailsDto> & Pick<PersonDetailsDto, 'personId'>>({
            // note: an optional `queryFn` may be used in place of `query`
            query: ({personId, ...patch}) => ({
                url: `updatePerson/${personId}`,
                method: 'PATCH',
                body: patch,
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response: { data: PersonDetailsDto }, meta, arg) => response.data,
            invalidatesTags: ['Person'],
        })
    })
})

export const {
    useGetPersonDetailsQuery,
    useLazyGetPersonDetailsQuery,
    useGetPersonQuery,
    useLazyGetPersonQuery,
    useUpdatePersonMutation,
    useGetAuthorisedPersonsQuery,
    useSearchAuthorisedPersonMutation,
    useSearchPersonDetailsMutation
} = personsApi

