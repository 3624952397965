import api from './apiClient'

export const createAttachment = async (data) => {
    
    return api.post('CreateAttachment', data);
}

export const getAttachment = async (id) => {
    const result = api.get('GetAttachmentById?id='+ id,{
    responseType: 'blob',
    timeout: 30000,
    });
    return result;
    }