import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

function CheckboxGroup(props) {
    const { label, name, inputRequired,  options, ...rest } = props
    return (
        <div className='form-ctrl'>
            <label htmlFor={name} className={inputRequired ? "control-required" : ""}>{label}</label>
            {
                options && <Field name={name}>
                    {({ field }) => {
                        return (options && options.map(option => {
                            return (
                                <React.Fragment key={option.name}>
                                    <span style={{ display: 'inline-block' }}>
                                    <input
                                        type='checkbox'
                                        id={name + option.id}
                                        {...field}
                                        {...rest}
                                        value={option.id}
                                        checked={field.value.includes(option.id + "")}
                                    /> &nbsp;
                                    <label htmlFor={name + option.id}>{option.name}</label>
                                    </span>
                                </React.Fragment>
                            )
                        }))
                    }}
                </Field>
            }
            <ErrorMessage component={TextError} name={name} />
        </div>
    )
}

export default CheckboxGroup
