import api from './apiClient'

export const getActs = async () => {
    return api.get('getActs')
}
export const getAllActs = async () => {
    return api.get('getAllActs')
}


export const getSectionsByActId = async (id) => {
    return api.get('getSections?actId=' + id)
}


export const getCourtTribunallist = async () => {
    return api.get('getcourttribunallist')
}


export const getStates = async () => {
    return api.get('getStates')
}
export const getStatesByCourtTribunalId = async (id) => {
    return api.get('getStatesByCourtTribunalId?stateId=' + id)
}

export const getCitys = async (id) => {
    return api.get('getcities?stateId=' + id)
}


export const getContactTypelist = async () => {
    return api.get('getcontacttypelist')
}


export const getStatus = async () => {
    return api.get('getStatus')
}

export const getPosition = async () => {
    return api.get('getPosition')
}

export const getEmploymentType = async () => {
    return api.get('getEmploymentType')
}

export const getTermType = async () => {
    return api.get('getTermType')
}

export const getEndReason = async () => {
    return api.get('getEndReason')
}

export const getContactById = async (id) => {
    return api.get('getContactById?contactId=' + id)
}

export const updateContact = async (data) => {
    return api.post('updateContact', data)
}

export const deleteContact = async (ids) => {
    return api.post('deleteContact', ids)
}

export const getAllActiveAppointments = async () => {
    return api.get('getAllActiveAppointments')
}

export const deleteRegistry = async (ids) => {
    return api.post('deleteRegistry', ids)
}