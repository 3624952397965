import * as React from "react";
import {Button, Card, Col, Row} from "react-bootstrap";
import {Form, Formik} from 'formik';
import FormikControl from '../../components/FormikControl';
import {PersonDetailsDto} from "dto/PersonDetailsDto";
import * as Yup from 'yup';
import {RouterPrompt} from "controls/RouterPrompt";

interface PersonInfoComponentProps {
    personDetails: PersonDetailsDto;
    readonly: boolean;
    handleCancel: () => void
    handleSubmit: (values: any) => void
}

const PersonInfoComponent = (props: PersonInfoComponentProps) => {


    const onSubmit = async (values: PersonDetailsDto) => {
        if (values) {
            props.handleSubmit(values);
        }
    }

    const validationSchema = () =>
        Yup.lazy((values) =>
            Yup.object({
                firstName: Yup.string().trim().max(100, 'Given Name is too Long').matches(/^[ A-Za-z_@./&\-()]*$/, 'Please enter valid Name').required('This is a required field'),
                surname: Yup.string().trim().max(100, 'Surname is too Long').matches(/^[ A-Za-z_@./&\-''’""]*$/, 'Please enter valid Name').required('This is a required field'),
                title: Yup.string().nullable().trim().max(100, 'Title is too Long').matches(/^[ A-Za-z_@./&\-()]*$/, 'Please enter valid Title'),

                preferredName: Yup.string().nullable().trim().max(100, 'Preferred Name is too Long').matches(/^[ A-Za-z_@./&\-()]*$/, 'Please enter valid Name'),
                middleName: Yup.string().nullable().trim().max(100, 'Middle Name is too Long').matches(/^[ A-Za-z_@./&\-()]*$/, 'Please enter valid Name'),
                postNominal: Yup.string().nullable().trim().max(100, 'Post Nominal is too Long').matches(/^[ A-Za-z_@./&\-()]*$/, 'Please enter valid Name'),
                previousName: Yup.string().nullable().trim().max(100, 'Previous Name is too Long').matches(/^[ A-Za-z_@./&\-()]*$/, 'Please enter valid Name'),
            }))

    return ( <Card className='table-card'>
                        <Card.Header>
                            <Card.Title as='h5'>Person Details</Card.Title>
                        </Card.Header>
                        <Card.Body className='pb-0'>
                            <div style={{height: '100%'}}>
                                <Formik enableReinitialize={true} initialValues={props.personDetails}
                                        onSubmit={onSubmit}
                                        validationSchema={validationSchema}
                                >
                                    {
                                        formik => {
                                            const {
                                                values,
                                                
                                                
                                                dirty,
                                                isValid,
                                                resetForm
                                            } = formik;
                                            return (
                                                <Form placeholder={undefined}>
                                                    {/*<RouterPrompt
                                                        when={dirty && (props.personDetails.personId !==0)}
                                                        title="Unsaved changes"
                                                        cancelText="Close"
                                                        okText="Discard"
                                                        onOK={() => true}
                                                        onCancel={() => false}
                                            />*/}
                                                    <fieldset disabled={props.readonly}>
                                                        <Row>
                                                            <Col sm={6}>
                                                                <FormikControl control='input' type='text' label='Title'
                                                                               name='title' value={values.title}/>
                                                            </Col>
                                                            <Col sm={6}>
                                                                <FormikControl control='input' type='text' inputRequired
                                                                               label='Given Name' name='firstName'
                                                                               value={values.firstName}/>
                                                            </Col>
                                                        </Row>
                                                        <br/>
                                                        <Row>
                                                            <Col sm={6}>
                                                                <FormikControl control='input' type='text'
                                                                               label='Preferred Name'
                                                                               name='preferredName'
                                                                               value={values.preferredName}/>
                                                            </Col>
                                                            <Col sm={6}>
                                                                <FormikControl control='input' type='text'
                                                                               label='Middle Name(s)' name='middleName'
                                                                               value={values.middleName}/>
                                                            </Col>
                                                        </Row>
                                                        <br/>
                                                        <Row>
                                                            <Col sm={6}>
                                                                <FormikControl control='input' type='text' inputRequired
                                                                               label='Surname' name='surname'
                                                                               value={values.surname}/>
                                                            </Col>
                                                            <Col sm={6}>
                                                                <FormikControl control='input' type='text'
                                                                               label='Post-Nominal' name='postNominal'
                                                                               value={values.postNominal}/>
                                                            </Col>
                                                        </Row>
                                                        <br/>
                                                        <Row>
                                                            <Col sm={6}>
                                                                <FormikControl control='input' type='text'
                                                                               label='Previous Name(s)'
                                                                               name='previousName'
                                                                               value={values.previousName}/>

                                                            </Col>
                                                        </Row>
                                                        <br/>
                                                        <Row>
                                                            <Col>
                                                                <Button variant="outline-info" type='submit'
                                                                        hidden={props.readonly}
                                                                        disabled={(!(dirty && isValid))}
                                                                        className='mr-1 float-right'>Save</Button>
                                                                 <Button variant="outline-info"
                                                                        hidden={true}
                                                                        disabled={(!(dirty))}
                                                                        onClick={() => {
                                                                            if (values.personId === 0) {
                                                                                props.handleCancel()
                                                                            } else {
                                                                                resetForm({values: props.personDetails})
                                                                            }
                                                                        }}
                                                                        className='mr-1 float-right'>Cancel</Button> 
                                                            </Col>
                                                        </Row>
                                                        <br/>
                                                    </fieldset>
                                                </Form>
                                            )
                                        }
                                    }
                                </Formik>

                            </div>

                        </Card.Body>
                    </Card>
    );
}
export default PersonInfoComponent