import { Row, Col, Card } from 'react-bootstrap'
import React from 'react';

const Page404 = () => {
  
  return (
    <React.Fragment>
    <Row>
        <Col md={12}>
            <div className="auth-wrapper">
                <div className="auth-content text-center">
                    <Card className='table-card'>
                        <Card.Header>
                            <Card.Title as='h4'>404</Card.Title>
                        </Card.Header>
                        <Card.Body className='pb-0'>
                            <div style={{ height: '100%' }}>
                                <p>
                                    The requested URL was not found.
                                </p>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </Col>
    </Row>
    

</React.Fragment>
  );
};

export default Page404;
