import React, { useEffect, Suspense  } from 'react'
import { Route, Routes } from 'react-router'
import { PublicRoutes } from './PublicRoutes'
import { useLocation } from 'react-router-dom'
import SignIn from 'views/SignIn'
import Page404 from 'views/Page404'

export const PublicRouter = () => {

  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0) // scroll to top on route change
  }, [pathname])



  return (
    <Suspense fallback={<div>Loading..</div>}>
    <Routes>
      {/* Remove tailing slashes in urls */}
      <Route from="/:url*(/+)" to={pathname.slice(0, -1) + window.location.search} />

      {PublicRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={<SignIn/> }>
        </Route>
      ))}

      {/* ERROR route */}
      <Route path="*" element={<Page404/>} />

    </Routes>
    </Suspense>
  )
}


