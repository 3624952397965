import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { CContainer, CSpinner } from '@coreui/react'

// routes config

const AppContent = ({route}) => {
  return (
    <CContainer lg>
      <Suspense fallback={<CSpinner color="primary" />}>
      <Outlet/>
      </Suspense>
    </CContainer>
  )
}

export default React.memo(AppContent)
