import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {api} from 'app/services/api';
import navigationReducer from './navigationSlice';
import personsReducer from "../features/persons/personsSlice";
import authorisedPersonsReducer from 'features/persons/authorisedPersonsSlice';


export const createStore = (
  options?: ConfigureStoreOptions['preloadedState'] | undefined
) => configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    navigation: navigationReducer,
    persons: personsReducer,
    authorisedPersons: authorisedPersonsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(api.middleware),
  ...options,
});
export const store = createStore()

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch
export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
