import React from 'react';

import {errorToast} from '../../utils/toast';
import {getAttachment} from '../../services/attachmentService';
import FileSaver from "file-saver";

export interface AttachmentViewProps {
    attachmentId: number;
    fileName: string;
}

const AttachmentView: React.FC<AttachmentViewProps> = (props) => {

    const downloadAttachment = async (e: any) => {
        e.preventDefault();
        const name = props.fileName;
        await getAttachment(props.attachmentId).then((result) => {
            if (result !== 0) {
                var blob = new Blob([result], {type: "application/pdf;charset=utf-8"});
                FileSaver.saveAs(blob, name);
            }
        }).catch((err) => {
            console.log("err -> ", JSON.stringify(err));
            errorToast('Failed to get Id.')
        }).finally(() => {

        });
    }

   return (
       <>
        <a href="#" onClick={(e: any) => downloadAttachment(e)}>{props.fileName}</a>
       </>

    );
};
export default AttachmentView
