import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

function Checkbox(props) {
    const { label, name, inputRequired } = props
    return (
        <div className='form-ctrl'>
            <Field name={name} label={label} type="checkbox" component={(props) => (
                <>
                    <span style={{ display: 'inline-block' }}>
                        <input {...props.field} type="checkbox" /> &nbsp;             
                        <label htmlFor={name} className={inputRequired ? "control-required" : ""}>{props.label}</label>               
                    </span>
                    </>
                )} />
            <ErrorMessage component={TextError} name={name} />
        </div>
    )
}

export default Checkbox
