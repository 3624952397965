
import React, { Component } from 'react';
import './Header.css';
//import {Navbar, Container, NavbarBrand,Collapse} from 'react-bootstrap';
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CImage
} from '@coreui/react'

class Header extends Component {

  render () {
    return (
   
<CHeader position="fixed" className="flex-shrink-0"  style = {{height: '6rem'}}>
 <CContainer fluid className = 'fullheader'>
    
 <div className="clearfix">
<CImage align="start" rounded src="../images/images/gov-agd-logo.png" width={300} height={75} />
 </div>
 <CHeaderBrand href="#">
 <h4 className = 'logo-text' style = {{display: 'inline-block'}} >Register</h4>
   </CHeaderBrand>

   </CContainer>
</CHeader>
   
    );
  }
}
export default Header;

