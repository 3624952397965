import React, {useState, useEffect} from 'react';
import {getcommissionSectionbycommissionSectionid, updateCommissionSection} from '../../services/persondetailsdata';
import Spinner from '../../components/Loader/Spinner';
import {errorToast, successToast} from '../../utils/toast';
import {useNavigate, useLocation} from "react-router-dom";
import moment from 'moment';
import {CommissionSectionUIModel} from "../../dto/CommissionSectionUIModel";
import {CommissionSectionResultModel} from "../../dto/CommissionSectionResultModelDto";
import CommissionFormComponent from "./CommissionFormComponent";
import {BreadcrumbType} from "../../components/AppBreadcrumb";
import {useAppDispatch} from "../../app/store";
import {saveCurrentBreadcrumbs} from "../../app/navigationSlice";
import {personsApi} from "../../app/services/personApi";

const CommissionEditView = () => {

    const breadcrumbs: BreadcrumbType[] = [];
    const [isBusy, setBusy] = useState<boolean>(false);
    
    function useQuery() {
        const {search} = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }


    let query = useQuery();
    const commissionSectionId = (query.get("commissionSectionId"));

    const history = useNavigate();

    const [entity, setEntity] = useState<CommissionSectionUIModel>(
        {
            commissionSectionId: 0,
            act: "",
            section: "",
            consentstartdate: "",
            consentenddate: "",
            commissionStatusId: "",
            sections: [],
            note: "",
            attachments: [],
            createdDate: "",
            createdId: "",
            startDate: '',
            endDate: '',
            appointmentId: ''
            
        });

    const dispatch = useAppDispatch();
    //
    useEffect(() => {
        const fetchData = async () => {
            const data: CommissionSectionResultModel = await getcommissionSectionbycommissionSectionid(commissionSectionId);
            if (data) {
                setEntity({
                    commissionSection: data,
                    commissionSectionId: Number(commissionSectionId),
                    act: data.act.toString(),
                    section: data.section.toString(),
                    consentstartdate: data.consentstartdate,
                    consentenddate: data.consentenddate,
                    commissionStatusId: data.commissionStatusId.toString(),
                    sections: data.sections,
                    note: data.note,
                    attachments: data.attachments,
                    createdDate: data.createdDate,
                    createdId: data.createdId,
                    startDate: data.commissions!.startDate,
                    endDate: data.commissions!.endDate,
                    appointmentId: data.commissions!.commissionId.toString(),
                    appointment: {
                        commissionId: data.commissions.commissionId,
                        personId: data.commissions.personId,
                        startDate: data.commissions.startDate,
                        endDate: data.commissions.endDate,
                        title: data.commissions.title,
                        givenNames: data.commissions.givenNames,
                        surnames: data.commissions.surnames,
                        middleName: data.commissions.middleName,
                        city: data.commissions.city,
                        courtTribunal: data.commissions.courtTribunal,
                        state: data.commissions.state,
                        position: data.commissions.position
                    }
                });
            }
            breadcrumbs.push({
                active: false,
                name: "Person Details",
                pathname: "/persondetails"
            })
            breadcrumbs.push({
                active: false,
                name: "Edit Person",
                pathname: "/persondetails/editpersondetails?personId=" + data.commissions.personId
            })
           breadcrumbs.push({
                active: false,
                name: "Edit Appointment",
                pathname: "/persondetails/editpersondetails/editappointment?commissionId=" + data.commissions.commissionId
            });
            breadcrumbs.push({
                active: true,
                name: "Edit Commission",
                pathname: "/persondetails/editpersondetails/editappointment/editcommissionSection?commissionSectionId" + data.commissions.commissionId
            })
            dispatch(saveCurrentBreadcrumbs(breadcrumbs));
        }
        fetchData();
    }, []);

    const onSubmit = async (values: CommissionSectionUIModel) => {
        values.consentstartdate = values.consentstartdate ? moment(values.consentstartdate).format("YYYY-MM-DD") : null
        values.consentenddate = values.consentenddate ? moment(values.consentenddate).format("YYYY-MM-DD") : null
        setBusy(true);
        await updateCommissionSection(values).then((result) => {
            if (result.message !== null) {
                setEntity({...entity, ...values });
                errorToast(result.message);
            }
            else if (result.commissionSectionId !== 0) {
                dispatch(personsApi.util.invalidateTags(['AuthorisedPersons']));
                dispatch(personsApi.util.invalidateTags(['PersonDetails']));
                setEntity({...entity, ...values });
                successToast("Saved Successfully", 2000);
            }
            else {
                errorToast('Something went wrong, please try again');
            }
        }).catch((err) => {
            console.log("err -> ", JSON.stringify(err));
            errorToast('Failed to get Id.')
        }).finally(() => {
            setBusy(false);
        });
    }

    return (entity?.Id !==0? <React.Fragment>
            {(isBusy) ? <Spinner/> :
                <CommissionFormComponent
                    entity={entity}
                    personId={"0"} appointmentId={"0"}
                    handleCancel={() => {
                        history(-1);
                    }}
                    handleSubmit={onSubmit} readonly={false}
                />
            }
        </React.Fragment>
            : (
                <span>Loading Appointment...</span>
            ));
};
export default CommissionEditView;
