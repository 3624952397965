import React from 'react'
import { CFooter } from '@coreui/react'

const AppFooter = () => {
  return (
    <CFooter >
      <div>
        <a href="https://www.ag.gov.au/disclaimer" target="_blank" rel="noopener noreferrer" >
         Disclaimer
        </a>
        <span className="ms-1"> <a href="https://www.ag.gov.au/about-us/accountability-and-reporting/privacy-policy" target="_blank" rel="noopener noreferrer" >
         Privacy Statement
        </a></span>
          <span className="ms-1"> <a href="/documents/2705ProtocolForUsingAndUpdatingTheRegister3.pdf" target="_blank" rel="noopener noreferrer" >
         Protocol for using and updating the Register
        </a></span>
      </div>
      <div className="ms-auto">
        <span className="me-1"></span>
        <a href="https://www.ag.gov.au/www/agd/agd.nsf/Page/About_the_DepartmentCopyright" target="_blank" rel="noopener noreferrer">
          Copyright
        </a>
      </div>
    </CFooter>
  )
}

export default React.memo(AppFooter)
