import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store"
import {BreadcrumbType} from "../components/AppBreadcrumb";

export interface NavigationState {
    sidebarShow: boolean;
    sidebarUnfoldable: boolean;
    breadcrumbs: BreadcrumbType[];
}

const initialState: NavigationState = {
    sidebarShow: true,
    sidebarUnfoldable: false,
    breadcrumbs: []
}


export const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        setMenu: (state, action: PayloadAction<boolean>) => {
            state.sidebarShow = action.payload
        },
        setSidebarUnfoldable: (state, action: PayloadAction<boolean>) => {
            state.sidebarUnfoldable = action.payload
        },
        saveCurrentBreadcrumbs: (state, action: PayloadAction<BreadcrumbType[]>) => {
            state.breadcrumbs = action.payload
        }
    }
});

export const {setMenu, setSidebarUnfoldable, saveCurrentBreadcrumbs} = navigationSlice.actions;

export const selectSideBarShow = (state: RootState) => state.navigation.sidebarShow;
export const selectSideUnfoldable = (state: RootState) => state.navigation.sidebarUnfoldable;
export const selectCurrentBreadcrums = (state:RootState) => state.navigation.breadcrumbs;

export default navigationSlice.reducer;