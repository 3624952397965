import React, {useState} from 'react';
//import { useForm, Controller } from 'react-hook-form';
import {Card, Button} from "react-bootstrap";
import PerfectScrollbar from 'react-perfect-scrollbar';
import BasicTableControl from '../../controls/BasicTableControl';
import * as constants from '../../utils/constants';
import {UserContext} from '../../contexts/UserContext';
import {errorToast} from '../../utils/toast';
import ConfirmModal from '../../components/confirmModal';
import { removeAttachment } from 'services/persondetailsdata';
import { useLocation } from 'react-router-dom';

export interface AttachmentListComponentProps {
    attachmentData: any,
}

const AttachmentListComponent: React.FC<AttachmentListComponentProps> = (props) => {

    const {checkFeatureAccess, user} = React.useContext(UserContext);

    const [selectedRows, setSelectedRows] = useState([]);

    const [modalShow, setModalShow] = useState(false);

    const [attachmentDataState, setAttachmentDataState] = useState(props.attachmentData);

    
    function useQuery() {
        const {search} = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    const commissionSectionId = (query.get("commissionSectionId"));

    const onRemoveAttachment = async () => {
        var attchmentIds = selectedRows.map((x: any) => x.attachmentId);
        console.log(attchmentIds)
        await removeAttachment(attchmentIds, commissionSectionId).then((result) => {
                     if (result != null) {
                       setModalShow(false);
                       setSelectedRows([]);
                       setAttachmentDataState(result);

                     };
                 }).catch((err) => {
                     console.log("err -> ", JSON.stringify(err));
                     errorToast('Failed to save registry address.')
                 }).finally(() => {
                 });
      }

   return (
       <>
        <Card className='table-card'>
            <Card.Header>
                <Card.Title as="h5"> Attachments
                    {checkFeatureAccess(user, 'Remove Attachment') && selectedRows.length > 0 &&
                        <Button variant="outline-info"
                                                            onClick={() => setModalShow(true)}
                                                            className='mr-1 float-right'>Remove
                    Attachment</Button>} 
                </Card.Title>
            </Card.Header>
            <Card.Body className='pb-0'>
                        <div style={{height: '100%'}}>
                <PerfectScrollbar>
                    <BasicTableControl key={"appointment-section-list"}
                                        columns={constants.allAttachmentColumns}
                                    hideActionColumn={false}
                                    data={attachmentDataState}
                                    setSelectedRows={setSelectedRows}
                                    renderRowSubComponent={[]}/>
                </PerfectScrollbar>
                </div>
            </Card.Body>
        </Card>
            <ConfirmModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                heading="Remove Attachment(s)"
                body="Are you sure you want to remove the selected attachment(s)?"
                onDelete={onRemoveAttachment}
            />
            </>
    );
};
export default AttachmentListComponent
