import React from 'react'
import {CContainer, CHeader, CHeaderDivider, CHeaderNav, CHeaderText, CHeaderToggler, CImage} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {cilMenu} from '@coreui/icons'
import {AppBreadcrumb} from './index'
import {AppHeaderDropdown} from './header/index'

//import { Header } from '../components/index'
import {selectSideBarShow, setMenu} from 'app/navigationSlice';
import {useAppDispatch, useAppSelector} from "../app/store";

const AppHeader = () => {

    const dispatch = useAppDispatch();
    const sidebarShow = useAppSelector(selectSideBarShow);
    return (   
        <CHeader position="sticky" className="mb-4" style={{height: '12rem'}}>
            <CContainer fluid style={{backgroundColor: '#3c4b64', height: '7rem'}}>
                <div className="clearfix">
                    <CImage align="start" rounded src="../images/images/gov-agd-logo.png" width={300} height={63}/>
                </div>
                <CHeaderText>Register of Authorised Persons for Warrants and Other Functions</CHeaderText>
            </CContainer>
            <CHeaderDivider/>
            <CContainer fluid>
                <CHeaderToggler
                    className="ps-1"
                    onClick={() => dispatch(setMenu(!sidebarShow))}
                >
                    <CIcon icon={cilMenu} size="xl"/>
                </CHeaderToggler>
                <AppBreadcrumb/>
                <CHeaderNav className="d-none d-md-flex me-auto">
                </CHeaderNav>
                <CHeaderNav className="ms-3">
                    <AppHeaderDropdown/>
                </CHeaderNav>
            </CContainer>
        </CHeader>
        
    )
}
export default AppHeader
