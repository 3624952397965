import React from 'react';
import {Link} from 'react-router-dom';
import AttachmentView from '../features/attachments/AttachmentView'
import {v4} from 'uuid'

export const dateDisplayFormat = 'DD/MM/YYYY'
export const dateValueFormat = 'YYYY-MM-DD'
export const dateTimeDisplayFormat = 'DD/MM/YYYY HH:mm:ss'

export const allActdetailsColumns = [
    {
        id: 'selection',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({getToggleAllPageRowsSelectedProps}) => (
            <div>
                <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({row}) => (
            <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
        ),
    },
    {
        Header: 'Acts',
        accessor: (acts) => ({id: acts.actId, desc: acts.description}),
        width: '90%',
        Cell: ({
                   value: {
                       id,
                       desc
                   },
               }) => <Link to={"sectiondetails?actId=" + id}>{desc} </Link>
    },
]

export const allSectiondetailsColumns = [
    {
        id: 'selection',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({getToggleAllPageRowsSelectedProps}) => (
            <div>
                <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({row}) => (
            <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
        ),
    },
    {
        Header: ' Section Name ',
        accessor: (sections) => ({id: sections.sectionId, desc: sections.description}),
        width: '90%',
        Cell: ({
                   value: {
                       id,
                       desc
                   },
               }) => <Link to={"editsection?sectionId=" + id}>{desc} </Link>
    },

]

export const allRegistryColumns = [
    {
        id: 'selection',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({getToggleAllPageRowsSelectedProps}) => (
            <div>
                <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({row}) => (
            <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
        ),
    },
    {
        Header: 'Court or Tribunal',
        accessor: (registries) => ({id: registries.registryId, desc: registries.courtTribunal}),
        width: '50%',
        Cell: ({
                   value: {
                       id,
                       desc
                   },
               }) => <Link to={"viewregistrydetails?registryId=" + id}>{desc} </Link>
    },
    {
        Header: 'City',
        accessor: 'city',
        width: '20%',
    },
    {
        Header: 'State or Territory',
        accessor: 'state',
        width: '20%',
    },
]

export const allRegistryColumnsView = [
    {
        Header: 'Court or Tribunal',
        accessor: (registries) => ({id: registries.registryId, desc: registries.courtTribunal}),
        width: '50%',
        Cell: ({
                   value: {
                       id,
                       desc
                   },
               }) => <Link to={"viewregistrydetails?registryId=" + id}>{desc} </Link>
    },
    {
        Header: 'City',
        accessor: 'city',
        width: '20%',
    },
    {
        Header: 'State or Territory',
        accessor: 'state',
        width: '20%',
    },
]


export const allPhoneColumns = [
    {
        id: 'selection',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({getToggleAllPageRowsSelectedProps}) => (
            <div>
                <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({row}) => (
            <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
        ),
    },
    {
        Header: 'Contact',
        accessor: (phoneNumber) => ({
            id: phoneNumber.contactId,
            registryId: phoneNumber.registryId,
            desc: phoneNumber.namePositionTitle
        }),
        width: '50%',
        Cell: ({
                   value: {
                       id,
                       registryId,
                       desc
                   },
               }) => <Link to={"editphonenumber?contactId=" + id + "&registryId=" + registryId}>{desc} </Link>
    },
    {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
        width: '20%',
    },
    {
        Header: 'Contact Type',
        accessor: 'contactType',
        width: '20%',
    },

    {
        Header: 'Sort Order',
        accessor: 'sortOrder',
        width: '20%',
    },
]

export const allPhoneColumnsView = [
    {
        Header: 'Contact',
        accessor: (phoneNumber) => ({
            id: phoneNumber.contactId,
            registryId: phoneNumber.registryId,
            desc: phoneNumber.namePositionTitle
        }),
        width: '50%',
        Cell: ({
                   value: {
                       id,
                       registryId,
                       desc
                   },
               }) => <Link to={"editphonenumber?contactId=" + id + "&registryId=" + registryId}>{desc} </Link>
    },
    {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
        width: '20%',
    },
    {
        Header: 'Contact Type',
        accessor: 'contactType',
        width: '20%',
    },

    {
        Header: 'Sort Order',
        accessor: 'sortOrder',
        width: '20%',
    },
]

export const allPersonsColumns = [
    {
        id: 'selection',
        key: 'selectionKey',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({getToggleAllPageRowsSelectedProps}) => (
            <div>
                <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({row}) => (
            <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
        ),
    },
    {
        Header: 'Name',
        key: 'NameKey',
        accessor: (persons) => ({id: persons.personId, desc: persons.title + " " + persons.fullName}),
        width: '50%',
        Cell: ({
                   value: {
                       id,
                       desc
                   },
               }) => <Link to={"editpersondetails?personId=" + id}>{desc} </Link>

    },

    {
        Header: 'Court or Tribunal',
        key: "CourtOrTribunalKey",
        accessor: (persons) => ({id: persons.commissionId, desc: persons.courtTribunal}),
        Cell: ({
                   value: {
                       id,
                       desc
                   },
               }) => <Link to={"/persondetails/editpersondetails/editappointment?commissionId=" + id}>{desc} </Link>,

        width: '20%',

    },
    {
        Header: 'Position',
        key: 'PostionKey',
        accessor: 'position',
        width: '20%',

    },

    {
        Header: 'Start date',
        key: 'StartDateKey',
        accessor: 'startDate',
        width: '20%',
    },
    {

        Header: 'End Date',
        key: 'EndDateKey',
        accessor: 'endDate',

        width: '20%',
    },
    // {
    // Header: 'Action',
    //  accessor: 'action',
    //  width: '20%',
    //   Cell: row => (
    //   <div>
    //         <Button variant="info" className='mr-1 float-center' onClick={e=> handleEdit(row.row.original)}>Edit</Button>
    //    </div>
    //    ),
    // },

]

export const allPersonsColumnsToViewOnly = [
    // {
    //   id: 'selection',
    //   // The header can use the table's getToggleAllRowsSelectedProps method
    //   // to render a checkbox
    //   Header: ({ getToggleAllPageRowsSelectedProps }) => (
    //     <div>
    //       <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
    //     </div>
    //   ),
    //   // The cell can use the individual row's getToggleRowSelectedProps method
    //   // to the render a checkbox
    //   Cell: ({ row }) => (
    //     <div>
    //       <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //     </div>
    //   ),
    // },
    {
        Header: 'Name',
        accessor: (persons) => ({id: persons.personId, desc: persons.title + " " + persons.fullName}),
        width: '50%',
        Cell: ({
                   value: {
                       id,
                       desc
                   },
               }) => <Link to={"viewpersondetails?personId=" + id}>{desc} </Link>

    },

    {
        Header: 'Court or Tribunal',
        accessor: (persons) => ({id: persons.commissionId, desc: persons.courtTribunal}),
        Cell: ({
                   value: {
                       id,
                       desc
                   },
               }) => <Link to={"/persondetails/viewpersondetails/viewappointment?commissionId=" + id}>{desc} </Link>,

        width: '20%',

    },
    {
        Header: 'Position',
        accessor: 'position',
        width: '20%',

    },

    {
        Header: 'Start date',
        accessor: 'startDate',
        width: '20%',
    },
    {

        Header: 'End Date',
        accessor: 'endDate',

        width: '20%',
    },
]

export const allAuthPersonSummaryColumns = [
    {
        Header: 'Court or Tribunal',
        accessor: 'courtTribunal',
        width: '20%',
    },
    {
        Header: 'City',
        accessor: 'city',
        width: '20%',
    },
    {
        Header: 'State',
        accessor: 'state',
        width: '20%',
    },
    {
        Header: 'Address',
        accessor: 'address',
        width: '20%',
        //style: { 'whiteSpace': 'unset', overflowWrap: "break-word" }
        // Cell: props => props.value ? (props.value).split(',').map(x=><br>{x}</br>) : "",
        Cell: props => props.value ? (props.value).split(',').map((item, i) => <div style={{padding: 0,lineHeight: 1}}
            key={"address-" + v4()}>{item}</div>) : ""

    },
    {
        // id: "activeImrCommunicationId",
        Header: 'Contact',
        accessor: 'phoneNumbers',
        width: '20%',
        Cell: props => props.value ? (props.value).split('++').map((group, i) => 
            <ul style={{ listStyleType: "none", padding: 0, listStylePosition: "outside" }}            
            key={"contact-" + v4()}>{
            group.split('--').map(item => <li style={{lineHeight: 1}} key={"item-" + v4()}>{item}</li>)
        }</ul>) : ""
    },
    {
        // id: "activeImrCommunicationId",
        Header: 'Number of Authorised Persons',
        accessor: 'count',
        width: '20%',
        // Cell: props => <Link to={"communication/" + props.value + "?sourcePage=dashboard"}  data={props.value} > View </Link>,
    },
]

export const allCommissiondetailsColumns = [
    {
        // Make an expander cell
        Header: () => null, // No header
        id: 'expander', // It needs an ID
        Cell: ({row}) => {
            if (row.original.attachments.length > 0) {
                return (          // Use Cell to render an expander for each row.
                    // We can use the getToggleRowExpandedProps prop-getter
                    // to build the expander.
                    <span {...row.getToggleRowExpandedProps()}>
                      {/* {row.isExpanded ? '👇' : '👉'} */}
                        {row.isExpanded ? '-' : '+'}
                    </span>)
            }
            return <span/>
        },
    },
    {
        id: 'selection',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({getToggleAllPageRowsSelectedProps}) => (
            <div>
                <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({row}) => (
            <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
        ),
    },
    {
        Header: 'Act',
        width: '40%',

        accessor: (commissionSections) => ({id: commissionSections.commissionSectionId, desc: commissionSections.act}),
        Cell: ({
                   value: {
                       id,
                       desc
                   },
               }) => <Link to={"editcommissionSection?commissionSectionId=" + id}>{desc} </Link>,
    },
    {
        Header: 'Section',
        accessor: 'section',
        width: '30%',
    },
    {
        Header: 'Commission Status',
        accessor: 'commissionStatus',
        width: '30%',
    }

]

export const allCommissiondetailsViewColumns = [
    {
        // Make an expander cell
        Header: () => null, // No header
        id: 'expander', // It needs an ID
        Cell: ({row}) => {
            if (row.original.attachments.length > 0) {
                return (          // Use Cell to render an expander for each row.
                    // We can use the getToggleRowExpandedProps prop-getter
                    // to build the expander.
                    <span {...row.getToggleRowExpandedProps()}>
                    {/* {row.isExpanded ? '👇' : '👉'} */}
                        {row.isExpanded ? '-' : '+'}
                  </span>)
            }
            return <span/>
        },
    },
    {
        Header: 'Act',
        width: '40%',

        accessor: (commissionSections) => ({id: commissionSections.commissionSectionId, desc: commissionSections.act}),
        Cell: ({
                   value: {
                       id,
                       desc
                   },
               }) => <Link to={"viewcommissionSection?commissionSectionId=" + id}>{desc} </Link>,
    },
    {
        Header: 'Section',
        accessor: 'section',
        width: '30%',
    },
    {
        Header: 'Commission Status',
        accessor: 'commissionStatus',
        width: '30%',
    }

]

export const allAppointmentdetailsColumns = [
    {
        id: 'selection',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({getToggleAllPageRowsSelectedProps}) => (
            <div>
                <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({row}) => (
            <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
        ),
    },
    {
        Header: 'Court or Tribunal',
        //accessor: (persons) => console.log(persons),
        accessor: (persons) => ({id: persons.commissionId, desc: persons.courtTribunal, access: persons.access}),
        width: '10%',
        Cell: ({
                   value: {
                       id,
                       desc
                   },
               }) => <Link to={"/persondetails/editpersondetails/editappointment?commissionId=" + id}>{desc} </Link>,
    },
    {
        Header: 'Start date',
        accessor: 'startDate',
        width: '10%',
    },
    {
        Header: 'End Date',
        accessor: 'endDate',
        width: '10%',
    },

    {
        Header: 'City',
        accessor: 'city',
        width: '10%',
    },
    {
        Header: 'State or Territory',
        accessor: 'state',
        width: '10%',
    },
    {
        Header: 'Position',
        accessor: 'position',
        width: '10%',
    },
    {
        Header: 'Employment Type',
        accessor: 'employmentType',
        width: '10%',
    },
    {
        Header: 'End Reason',
        accessor: 'endReason',
        width: '20%',
    },
    {
        Header: 'Term Type',
        accessor: 'termType',
        width: '10%',
    },
]

export const allAppointmentdetailsColumnsToViewOnly = [
    {
        Header: 'Court or Tribunal',
        //accessor: 'courtTribunal',
        accessor: (persons) => ({id: persons.commissionId, desc: persons.courtTribunal}),
        width: '10%',
        Cell: ({
                   value: {
                       id,
                       desc
                   },
               }) => <Link to={"/persondetails/viewpersondetails/viewappointment?commissionId=" + id}>{desc} </Link>,
    },
    {
        Header: 'Start date',
        accessor: 'startDate',
        width: '10%',
    },
    {
        Header: 'End Date',
        accessor: 'endDate',
        width: '10%',
    },

    {
        Header: 'City',
        accessor: 'city',
        width: '10%',
    },
    {
        Header: 'State or Territory',
        accessor: 'state',
        width: '10%',
    },
    {
        Header: 'Position',
        accessor: 'position',
        width: '10%',
    },
    {
        Header: 'Employment Type',
        accessor: 'employmentType',
        width: '10%',
    },
    {
        Header: 'End Reason',
        accessor: 'endReason',
        width: '20%',
    },
    {
        Header: 'Term Type',
        accessor: 'termType',
        width: '10%',
    },

]

export const allAttachmentColumns = [
    {
        id: 'selection',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({getToggleAllPageRowsSelectedProps}) => (
            <div>
                <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
        ),
        width: '10%',
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({row}) => (
            <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
        ),
    },
    {
        Header: 'Name',
        accessor: (attachment) => ({id: attachment.attachmentId, _fname: attachment.fileName}),
        //width: '90%',
        Cell: ({
                   value: {
                       id,
                       _fname
                   },
               }) => <AttachmentView attachmentId={id} fileName={_fname}/>
        //  <a href="#"
        //  onClick={(e) => downloadAttachment(item, e)}>{desc}</a>,
        //  <Link to = {"editappointment?commissionId=" + id}>{ desc} </Link>,
    },
]

const IndeterminateCheckbox = React.forwardRef(
    ({indeterminate, ...rest}, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)