import React, {useContext, useState} from 'react';
//import { useForm, Controller } from 'react-hook-form';
import {Col, Card, Button} from "react-bootstrap";
import PerfectScrollbar from 'react-perfect-scrollbar';
import BasicTableControl from '../../controls/BasicTableControl';
import * as constants from '../../utils/constants';
//import { SystemContext } from '../contexts/SystemContext'
//import { getActs } from '../services/referenceData';
import {UserContext} from '../../contexts/UserContext';
import {errorToast} from '../../utils/toast';
import {useNavigate} from "react-router-dom";
import DropZone from '../../components/DropZone'
import {createAttachment, getAttachment} from '../../services/attachmentService';
import FileSaver from "file-saver";

import {CommissionResultModelDto} from "../../dto/CommissionResultModelDto";
import ConfirmModal from '../../components/confirmModal';
import {removeCommission, searchAppointmentDatabyid} from 'services/persondetailsdata';
import {personsApi} from "../../app/services/personApi";
import {useAppDispatch} from "../../app/store";
import {CommissionSectionDetailsDto} from "../../dto/CommissionSectionDetailsDto";


import {AttachmentResponseModelDto} from "../../dto/AttachmentResponseModelDto";

export interface CommisionListComponentProps {
    commisionId: number,
    appointmentData: CommissionResultModelDto,
    canEditCommission: boolean,
    readOnly: boolean
}

const CommissionListComponent: React.FC<CommisionListComponentProps> = (props) => {

    const userContextValue = useContext(UserContext);
    const [selectedRows, setSelectedRows] = useState<CommissionSectionDetailsDto[]>([]);

    const [attachmentData, setAttachmentData] = useState();
    const [attachmentName, setAttachmentName] = useState('');
    const [canAttach, setCanAttach] = useState(true);

    const [modalShow, setModalShow] = useState(false);

    const [commissionResults, setCommissionResults] = useState(props.appointmentData.commissionSections);

    const {checkFeatureAccess, user} = useContext(UserContext);
    const dispatch = useAppDispatch();
    const handleFileUpload = (binaryStr: any, file: any) => {
        setAttachmentData(binaryStr)
        setAttachmentName(file.name)
        if (file.path !== null) {
            setCanAttach(false);
        }
    }

    const handleAttachment = async (e: any) => {
        e.preventDefault();
        var userName = `${userContextValue.user.givenName + " " + userContextValue.user.surName}`
        var values= selectedRows.map(x => ({
            commissionId: props.appointmentData.commissionId,
            // @ts-ignore
            sectionId: x.sectionId,
            description: (attachmentName: string)=> {
                return attachmentName.length > 100 ? attachmentName.substring(0, 99) : attachmentName;
            },
            fileName: attachmentName,
            attachmentData: arrayBufferToBase64String(attachmentData),
            createdId: userName,
            lastModifiedId: userName,
        }))
        await createAttachment(values).then(async (result:AttachmentResponseModelDto) => {
            if (result.commissionId !== 0) {
                // commissionResults.filter( c => c.commissionSectionId == values.sectionId)
                await searchAppointmentDatabyid(result.commissionId).then((data: CommissionResultModelDto) => {
                    setCommissionResults(data.commissionSections);
                });


                // window.location.reload();
                // setSelectedRows([]);
                // RouteCommissionPage(commissionId);
            }

        }).catch((err) => {
            console.log("err -> ", JSON.stringify(err));
            errorToast('Failed to get Id.')
        }).finally(() => {

        });
    }

    const arrayBufferToBase64String = (buffer: any) => {
        let binaryString = ''
        var bytes = new Uint8Array(buffer);
        for (var i = 0; i < bytes.byteLength; i++) {
            binaryString += String.fromCharCode(bytes[i]);
        }

        return window.btoa(binaryString);
    }

    const history = useNavigate();

    

    const Routepagenew = () => {
        // console.log('route' + commissionId)
        history('/persondetails/editpersondetails/editappointment/addcommission?commissionId=' + props.commisionId);
    }

    

    

    const downloadAttachment = async (attachment: any, e: any) => {
        e.preventDefault();
        const name = attachment.description;
        await getAttachment(attachment.attachmentId).then((result) => {
            if (result !== 0) {
                var blob = new Blob([result], {type: "application/pdf;charset=utf-8"});
                FileSaver.saveAs(blob, name);
            }
        }).catch((err) => {
            console.log("err -> ", JSON.stringify(err));
            errorToast('Failed to get Id.')
        }).finally(() => {

        });
    }
    const renderRowSubComponent = React.useCallback(
        // @ts-ignore
        ({row}) => (
            // @ts-ignore
            <ul> {row.original.attachments.map((item, i) => {
                return <li key={i}><a href="#" onClick={(e) => downloadAttachment(item, e)}>{item.description}</a></li>
            })}
            </ul>
        ),
        []
    )

    const onRemoveCommission = async () => {
        var commissionSectionIds = selectedRows.map((x: any) => x.commissionSectionId);
        console.log(commissionResults)
        await removeCommission(commissionSectionIds, props.commisionId).then((result) => {
            if (result != null) {
                setModalShow(false);
                setSelectedRows([]);
                setCommissionResults(result);
                dispatch(personsApi.util.invalidateTags(['AuthorisedPersons']));
                dispatch(personsApi.util.invalidateTags(['PersonDetails']));

            }
            ;
        }).catch((err) => {
            console.log("err -> ", JSON.stringify(err));
            errorToast('Failed to save registry address.')
        }).finally(() => {
        });
    }

    return (
        <>
            <Col>
                <Card className='table-card'>

                    <Card.Header>
                        <Card.Title as="h5"> Commission(s)
                            {/* <span>To add Attachment, select one or more Commissions using checkboxes.</span>
                                      <span>Attachments can be viewed by selecting + to expand the Commission.</span> */}
                            {checkFeatureAccess(user, 'Remove Commission') && selectedRows.length > 0 &&
                                <Button variant="outline-info"
                                        className='mr-1 float-right' onClick={() => setModalShow(true)}
                                    disabled={props.readOnly} >Remove
                                    Commission</Button>}
                            {checkFeatureAccess(user, 'Add Commission') &&
                                <Button variant="outline-info" onClick={(event) => {
                                    Routepagenew();
                                }}
                                        className='mr-1 float-right' disabled={props.readOnly} >Add New Commission</Button>}
                        </Card.Title>
                    </Card.Header>
                    {props.appointmentData &&
                        <Card.Body className='pb-0'>
                            <div style={{height: '100%'}}>
                                <PerfectScrollbar>
                                    <BasicTableControl key={"appointment-section-list"}
                                                       columns=
                                                           {props.canEditCommission ?
                                                               constants.allCommissiondetailsColumns
                                                               : constants.allCommissiondetailsViewColumns}
                                                       hideActionColumn={!props.readOnly}
                                                       data={commissionResults}
                                                       setSelectedRows={setSelectedRows}
                                                       renderRowSubComponent={renderRowSubComponent}/>
                                </PerfectScrollbar>
                            </div>
                            {checkFeatureAccess(user, 'Add Attachment') && selectedRows.length > 0 &&
                                <div>
                                    <h5>
                                        Add Attachment
                                        <DropZone handleFileUpload={handleFileUpload}/>
                                    </h5>


                                    <Button variant="outline-info" onClick={handleAttachment}
                                            className='mr-1 float-right' disabled={canAttach}>Add
                                        Attachment</Button>
                                </div>
                            }
                        </Card.Body>
                    }
                </Card>
            </Col>
            <ConfirmModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                heading="Remove Commission(s)"
                body="Are you sure you want to remove the selected commission(s)?"
                onDelete={onRemoveCommission}
            />
        </>

    );
};
export default CommissionListComponent
