import React from 'react'
import {Link} from 'react-router-dom'
import {CBreadcrumb, CBreadcrumbItem} from '@coreui/react'
import {useAppSelector} from "../app/store";
import {selectCurrentBreadcrums} from "../app/navigationSlice";

export interface BreadcrumbType {
    pathname: string
    name: string
    active: boolean
}

const AppBreadcrumb = () => {

    const viewBreadcrumbs = useAppSelector(selectCurrentBreadcrums);
    // const getRouteName = (pathname: string, routes: any[]) => {
    //     const currentRoute = routes.find((route) => route.path === pathname)
    //     return currentRoute.name
    // }

    // const getBreadcrumbs = (location: any) => {
    //
    //     const breadcrumbs: BreadcrumbType[] = [];
    //
    //     location.split('/').reduce((prev: string, curr: string, index: number, array: string[]) => {
    //         const currentPathname = `${prev}/${curr}`
    //         breadcrumbs.push({
    //             pathname: currentPathname,
    //             name: getRouteName(currentPathname, routes),
    //             active: index + 1 === array.length ? true : false,
    //         })
    //         return currentPathname
    //     })
    //     return breadcrumbs
    // }

    return (
        <CBreadcrumb className="m-0 ms-2">
            <CBreadcrumbItem href="/">Home</CBreadcrumbItem>
            {viewBreadcrumbs.map((breadcrumb: any, index: number) => {
                return (
                    <CBreadcrumbItem
                        {...(breadcrumb.active ? {active: true} : '')}
                        key={index}>
                        {breadcrumb.active ? breadcrumb.name : <Link to={breadcrumb.pathname}>{breadcrumb.name}</Link>}
                    </CBreadcrumbItem>
                )
            })}
        </CBreadcrumb>
    )
}

export default React.memo(AppBreadcrumb)
