import axios from 'axios';
import { timeout } from '../utils/timeout';
import { errorToast } from '../utils/toast';
import { log } from '../utils/logger';


//import { getAccessToken } from '../utils/authUtil';
import AuthService from './AuthService'

const API_BASE_URL = '';

const instance = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  mode :'cors',
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    'Accept': 'application/json'
} 
});

instance.interceptors.request.use(async (config) => {
  //console.log("inside apiClient")

  const token = await AuthService.getInstance().getToken()

  if (token) {
// console.log("token retrieved");
//console.log(token);
    config.headers.Authorization = `Bearer ${token.accessToken}`;
    //console.log(token.account.idTokenClaims.given_name);
 }
 return config;
});



instance.interceptors.response.use(
  (res) => res.data,
  (error) => {
    log('error getting response', { error });


      if (error?.response?.status === 401) {
      errorToast("Access denied.");
      return Promise.reject(error);
    }

    // global error handler
    if (error?.response?.data?.detail) {
      try {
        return Promise.reject(JSON.parse(error.response.data.detail));
      } catch (e) {
          errorToast("An unexpected error occurrred here.");
        return Promise.reject(error.response.data.detail);
      }
    }

    // catch all
    errorToast('Sorry, we cannot connect to the RAPWOF services now, please try again later.');
    return Promise.reject(error);
  }
);

//const get = async (url) =>  await instance.get(url);

const dummy = async (returnData, customDelay = 1000) => {
  await timeout(customDelay);
  return returnData ? returnData : { success: true };
};

const api = {
    ...instance,
  dummy
};

export default api
