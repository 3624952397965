import { Button, Form, Row, Col, Container } from 'react-bootstrap';
import bgImage from '../images/backgroundImage.jpg';
import { Link } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import React, {useState, useContext} from 'react';

const SignIn = () => {
  
  const { signIn } = useContext(UserContext)
  const [checked, setChecked] = useState(false)
 
  const handleSignIn = async () => {
    await signIn()
  }

  return (
    <Container fluid>
      <Row>
        <Col md="8" className="p-0">
          <div
            className='jumbotron jumbotron-fluid d-flex flex-column justify-content-center d-none d-sm-none d-md-block vh-120'
            style={{
              backgroundImage: `url("${bgImage}")`,
              backgroundSize: 'cover',
              marginBottom: '-1px',
              paddingBottom: '500px',
              paddingTop: '150px',
            }}
          >
            <div className='w-50 mx-auto'>
              <h1 className='display-3 font-weight-bold sign mb-0 signin-text'>RAPWOF</h1>
              <h3 className='mb-5 signin-text'>Register for Authorised Persons for Warrants and Other Functions</h3>
             

              
            </div>
          </div>
        </Col>
        <Col md="4"
          className='px-5 py-3'
          style={{ backgroundColor: 'rgb(247,247,252)' }}
        >
          <div className='page-header'>
            <h2 className='font-weight-bold'>Log in to your account</h2>
          </div>
          <div className='signin-bodytext'>
              <p><b> WARNING: </b>This Register for Authorised Persons for Warrants and Other Functions (the Register)
                  is for the use
                  of authorised users only.
                  All users are required to familiarise themselves with the Protocol for using and updating the Register
                  and to
                  implement the procedures for maintaining the information in the system outlined in this document.
                  Individuals using this Register without authority or in excess of their authority are subject to
                  having their
                  activities monitored and recorded by system personnel. Anyone using this Register expressly consents
                  to such
                  monitoring and is advised that if such monitoring reveals possible evidence of criminal activity,
                  system
                  personnel may provide the evidence of such monitoring to law enforcement officials. 
              </p>
              <p>If a user no longer requires access to the Register or transfers to another role, they must notify the
                  Attorney-General’s Department.</p>
              <p><b>Existing RAPWOF Authorised User</b> should use the 'Sign In' link to
                  manage their details. </p>
              <p><b>New users</b> should use the 'Create Account' option and
                  follow the instructions to create an account to be an authorised user.
                  If you have any questions about the RAPWOF Register, please contact the RAPWOF team by email:{' '}
                  <a href='mailto:orapwof@ag.gov.au '>
                      orapwof@ag.gov.au
                  </a></p>
          </div>

          <div>
          <Form.Label htmlFor="agreement" className="d-flex align-items-center">
                  <Form.Check
                    inline
                    id="agreement"
                    type="checkbox"
                    value={1}
                    required
                    onChange={(e) => setChecked(e.target.checked)}
                    checked={checked}
                    className="lg"
                  />

                  <span className='signin-bodytext'>I agree to comply with the Terms and Conditions of Use of this Service</span>
                </Form.Label>
            </div>
          <nav className='nav flex-column text-center'>
          <Button type="submit" className="btnsignin swatch-secondary mt-2" onClick={handleSignIn} disabled={!checked}>
                  <span className="d-flex align-items-center justify-content-center">Sign in</span>
                </Button>
                <p className='my-3'>or</p>
                <Button variant="outline-info" className='mr-1 float-Center'><Link to={window.__RUNTIME_CONFIG__.REACT_APP_EARS_URL.valueOf()} target="_blank" >Create Account</Link></Button>
                
                <p className='my-3'>or</p>
                <Button variant="outline-info" className='mr-1 float-Center'><Link to={window.__RUNTIME_CONFIG__.REACT_APP_EARS_FP.valueOf()} target="_blank" >Forgot Password</Link></Button>
                
                
          
          </nav>
        </Col>
      </Row>
    </Container>
  );
};

export default SignIn;
