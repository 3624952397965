import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

function Select (props) {
    const { label, name, inputRequired, options,readOnly, ...rest } = props
  return (
      // readOnly={props.readonly}

    <div className='form-ctrl'>
        <label htmlFor={name} className={inputRequired ? "control-required" : ""}>{label}</label>
          <Field as='select' id={name} name={name} className='form-control' {...rest}>
              {readOnly ? <option key={0} value="0"></option>
              : <option key={0} value="0">Select</option> }
              {options && options.map(option => {
                  return (
                      <option key={option.id} value={option.id} disabled={option.hasOwnProperty('disabled')? option.disabled: false}>
                          {option.name}
            </option>
          )
        })}
      </Field>
      {!readOnly && <ErrorMessage component={TextError} name={name} />}
    </div>
    
  )
}

export default Select
