import { InteractionRequiredAuthError } from '@azure/msal-browser';

import { msalInstance } from '../index';
import { msalConfig, loginRequest } from "../authConfig";

export const getAccessToken = async () => {
    try {
        //console.log("Inside getAccessToken in authUtil");
        const accounts = msalInstance.getAllAccounts();

        if (!accounts || !accounts[0]) {
            throw new InteractionRequiredAuthError();
        }
    
        const tokenRequest = {
            account: accounts[0],
            scopes: loginRequest.scopes,
            authority: msalConfig.auth.authority,
             redirectUri: msalConfig.auth.redirectUri,
            resourceRequestUri: msalConfig.auth.redirectUri,
        };

        // msal will return the cached token if present, or call to get a new one
        // if it is expired or near expiring.
        //console.log("acquireTokenSilent");
        return await msalInstance.acquireTokenSilent(tokenRequest);
    }
    catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            const tokenRedirectRequestPayload = {
                scopes: loginRequest.scopes,
                prompt: "select_account",
                redirectUri: msalConfig.auth.redirectUri
            };
            
            return await msalInstance.acquireTokenRedirect(tokenRedirectRequestPayload);
        }
        throw error;
    }
};