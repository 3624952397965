import api from './apiClient'


export const searchPersonsData = async (data) => {
    return api.get('GetPersons?firstName=' + data.firstName + '&surname=' + data.surname + '&searchActId=' + data.act + '&searchSectionId='
        + data.section + '&courttribunalid=' + data.court + '&cityid=' + data.city + '&stateid=' + data.state
        + '&appointmentStatusId=' + data.appointmentStatusId
        + '&commissionStatusId=' + data.commissionStatusId)
}

export const downloadCommission = async (data) => {
    const result = api.post('download', data)
    return result;
}


export const searchPersonData = async (personId) => {
    return api.get('GetPersonById?personId=' + personId)
}

export const searchAppointmentData = async (personId) => {
    return api.get('GetAppointmentById?personId=' + personId)
}

export const createPersonDetails = async (data) => {

    return api.post('createPerson', data);
}


export const createAppointmentDetails = async (data) => {

    return api.post('CreateAppointment', data);
}

export const searchAppointmentDatabyid = async (commissionId) => {
    return api.get('GetAppointmentByAppointmentId?commissionId=' + commissionId)
}

export const UpdateAppointment = async (data) => {
    return api.put('UpdateAppointment', data)
}


export const createCommissionSection = async (data) => {
    return api.post('CreateCommissionSection', data);
}


export const getcommissionSectionbycommissionSectionid = async (commissionSectionId) => {
    return api.get('GetcommSectionbycommSectionid?commissionSectionId=' + commissionSectionId)
}


export const updateCommissionSection = async (data) => {
    return api.put('updateCommissionSection', data)
}


export const removeCommission = async (ids, appointmentId) => {
    return api.post('removeCommission?appointmentId=' + appointmentId, ids)
}

export const updatePerson = async (data) => {
    return api.put('updatePerson', data)
}

export const removePerson = async (ids) => {
    return api.post('removePerson', ids)
}

export const removeAppointment = async (ids, personId) => {
    return api.post('removeAppointment?personId=' + personId, ids)
}

export const removeAttachment = async (ids, commissionSectionId) => {
    return api.post('removeAttachment?commissionSectionId=' + commissionSectionId, ids)
}
