import React from 'react'

import {CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler} from '@coreui/react'
//import CIcon from '@coreui/icons-react'
import {AppSidebarNav} from './AppSidebarNav'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import navigation from '../_nav'
import {selectSideBarShow, selectSideUnfoldable, setSidebarUnfoldable} from 'app/navigationSlice';
import {useAppDispatch, useAppSelector} from "../app/store";

const AppSidebar = () => {
  const dispatch = useAppDispatch();
  const unfoldable = useAppSelector(selectSideUnfoldable)
  const sidebarShow = useAppSelector(selectSideBarShow);

  return (
    <CSidebar style={{ top: 'auto',bottom:'auto' }}
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
      <CSidebarBrand className="d-none d-md-flex">
    
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch(setSidebarUnfoldable(!unfoldable))}
      />
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
