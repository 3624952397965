import React from 'react';
//import Progress from "./Progress";

const Loader = () => {
    return (
        <React.Fragment>
            
        </React.Fragment>
    );
};

export default Loader;