import React, { useContext } from 'react';
import {
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import { cilUser } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {UserContext} from '../../contexts/UserContext'
import SignInSignOutButton from '../../layout/SignInSignOutButton'

const AppHeaderDropdown = () => {

  const userContextValue = useContext(UserContext);
  //console.log(userContextValue);

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
      <CIcon size={'xl'} icon={cilUser} className="me-2" />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">Account</CDropdownHeader>
        <CDropdownItem href="#">
          {userContextValue.user.givenName + " " + userContextValue.user.surName }
        </CDropdownItem>
        <CDropdownItem href="#">
        AGSES\{userContextValue.user.uniqueName}
        </CDropdownItem>
       
        <CDropdownHeader className="bg-light fw-semibold py-2">Roles</CDropdownHeader>
 
          {userContextValue.user.roles.map((role)=>
       <CDropdownItem key = {role.order} href="#">
          <CIcon icon={cilUser} className="me-2" />{role.description}          
        </CDropdownItem>
          )}
     <CDropdownHeader className="bg-light fw-semibold py-2"><SignInSignOutButton /></CDropdownHeader>

      </CDropdownMenu>

     
    </CDropdown>
  )
}

export default AppHeaderDropdown
