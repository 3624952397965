import {api} from './api'
import {CourtTribunalDto} from "../../dto/CourtTribunalDto";
import {StateDto} from "../../dto/StateDto";
import {ReferenceDataModelDto} from "../../dto/ReferenceDataModelDto";
import {ContactTypeDto} from "../../dto/ContactTypeDto";

type CourtTribunalDtoResponse = CourtTribunalDto[];
type StatesResponse = StateDto[];
type ReferencesResponse =  ReferenceDataModelDto[]; 

export const refApi = api.injectEndpoints({
    endpoints: (build) => ({

        getCourtTribunals: build.query<CourtTribunalDtoResponse, void>({
            query: () => ( {
                    url: 'getcourttribunallist',
                    method: 'GET',
                    headers: {
                        'accept':'application/json',
                        'content-type':'application/json; charset=utf-8'
                    }
                }
            ),

            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ courtTribunalId }) => ({ type: 'CourtTribunals' as const, id: courtTribunalId })),
                        { type: 'CourtTribunals', id: 'LIST' },
                    ]
                    : [{ type: 'CourtTribunals', id: 'LIST' }],
        }),
        getStates: build.query<StatesResponse, void>({
            query: () => ( {
                    url: 'getStates',
                    method: 'GET',
                    headers: {
                        'accept':'application/json',
                        'content-type':'application/json; charset=utf-8'
                    }
                }
            ),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ stateId }) => ({ type: 'States' as const, id: stateId })),
                        { type: 'States', id: 'LIST' },
                    ]
                    : [{ type: 'States', id: 'LIST' }],
        }),
        getPositions: build.query<ReferencesResponse, void>({
            query: () => ( {
                    url: 'getPosition',
                    method: 'GET',
                    headers: {
                        'accept':'application/json',
                        'content-type':'application/json; charset=utf-8'
                    }
                }
            ),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: 'Positions' as const, id: id })),
                        { type: 'Positions', id: 'LIST' },
                    ]
                    : [{ type: 'Positions', id: 'LIST' }],
        }),
        getEmploymentTypes: build.query<ReferencesResponse, void>({
            query: () => ( {
                    url: 'getEmploymentType',
                    method: 'GET',
                    headers: {
                        'accept':'application/json',
                        'content-type':'application/json; charset=utf-8'
                    }
                }
            ),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: 'EmploymentTypes' as const, id: id })),
                        { type: 'EmploymentTypes', id: 'LIST' },
                    ]
                    : [{ type: 'EmploymentTypes', id: 'LIST' }],
        }),
        getTermTypes: build.query<ReferencesResponse, void>({
            query: () => ( {
                    url: 'getTermType',
                    method: 'GET',
                    headers: {
                        'accept':'application/json',
                        'content-type':'application/json; charset=utf-8'
                    }
                }
            ),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: 'TermTypes' as const, id: id })),
                        { type: 'TermTypes', id: 'LIST' },
                    ]
                    : [{ type: 'TermTypes', id: 'LIST' }],
        }),
        getEndReasons: build.query<ReferencesResponse, void>({
            query: () => ( {
                    url: 'getEndReason',
                    method: 'GET',
                    headers: {
                        'accept':'application/json',
                        'content-type':'application/json; charset=utf-8'
                    }
                }
            ),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: 'EndReasons' as const, id: id })),
                        { type: 'EndReasons', id: 'LIST' },
                    ]
                    : [{ type: 'EndReasons', id: 'LIST' }],
        }),
        
        //getcontacttypelist
        getContactTypes: build.query<ContactTypeDto[], void>({
            query: () => ( {
                    url: 'getcontacttypelist',
                    method: 'GET',
                    headers: {
                        'accept':'application/json',
                        'content-type':'application/json; charset=utf-8'
                    }
                }
            ),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ contactTypeId }) => ({ type: 'ContactTypes' as const, id: contactTypeId })),
                        { type: 'ContactTypes', id: 'LIST' },
                    ]
                    : [{ type: 'ContactTypes', id: 'LIST' }],
        }),

        getErrorProne: build.query<{ success: boolean }, void>({
            query: () => 'error-prone',
        }),
    })
})

export const {
    useGetCourtTribunalsQuery,
    useLazyGetCourtTribunalsQuery,
    useGetStatesQuery,
    useLazyGetStatesQuery,
    useGetEndReasonsQuery,
    useLazyGetEndReasonsQuery,
    useGetTermTypesQuery,
    useLazyGetTermTypesQuery,
    useGetEmploymentTypesQuery,
    useLazyGetEmploymentTypesQuery,
    useGetPositionsQuery,
    useLazyGetPositionsQuery,
    useLazyGetContactTypesQuery,
    useGetContactTypesQuery
} = refApi

export const {
    endpoints: {getCourtTribunals, getEndReasons, getStates, getTermTypes,getEmploymentTypes, getPositions},
} = refApi