import React from 'react'
import DateView from 'react-datepicker'
import {Field, ErrorMessage} from 'formik'
import TextError from './TextError'
import 'react-datepicker/dist/react-datepicker.css'

function DatePicker(props) {

    const {label, name, inputRequired, readOnly, ...rest} = props
    return (
        <div className='form-ctrl'>
            <label htmlFor={name} className={inputRequired ? "control-required" : ""}>{label}</label>
            <Field name={name} className='form-control'>
                {({form, field}) => {
                    const {setFieldValue} = form
                    const {value} = field
                    return (
                        <DateView
                            id={name}
                            className='form-control'
                            {...field}
                            readOnly={readOnly}
                            {...rest}
                            dateFormat="dd/MM/yyyy"
                            selected={(value && new Date(value)) || null}
                            onChange={val => setFieldValue(name, val)}
                            placeholderText={readOnly ? "" : "DD/MM/YYYY"}
                            strictParsing
                        />
                    )
                }}
            </Field>
            {!readOnly && <ErrorMessage component={TextError} name={name}/>}
        </div>
    )
}

export default DatePicker
