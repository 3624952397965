import * as React from "react";
import {useContext, useState, useEffect} from "react";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import PerfectScrollbar from 'react-perfect-scrollbar';

import * as constants from '../../utils/constants';
import {UserContext} from '../../contexts/UserContext';
import {useNavigate} from "react-router-dom";

import {SearchPersonFilterDto} from "../../dto/SearchPersonFilterDto";
import {useAppDispatch, useAppSelector} from "../../app/store";
import {
    resetPersonSearchFilter,
    savePersonSearchFilterAndResult, savePersonSearchResult,
    selectPersonsFilter,
    selectPersonsSearchResult,
    selectPersonsVisited
} from "./personsSlice";
import {
    personsApi,
    
    
    useSearchPersonDetailsMutation
} from "../../app/services/personApi";
import PersonSearchFilter from "./PersonSearchFilter";
import {BreadcrumbType} from "../../components/AppBreadcrumb";
import {saveCurrentBreadcrumbs} from "../../app/navigationSlice";


import BasicTableControl from "controls/BasicTableControl";
import ConfirmModal from "components/confirmModal";
import {downloadCommission, removePerson} from "services/persondetailsdata";
import {errorToast} from "utils/toast";


import {PersonSearchCriteriaModelDto} from "../../dto/PersonSearchCriteriaModelDto";



const PersonSearchView = () => {

    const {checkFeatureAccess, user} = useContext(UserContext);
    const [isDirty, setDirty] = useState<boolean> (false);
    const [isBusy, setBusy] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const breadcrumbs: BreadcrumbType[] = [{
        active: true,
        name: "Person Details",
        pathname: "/persondetails"
    }];

    const personSearchFilter = useAppSelector(selectPersonsFilter);
    const personsSerchResult = useAppSelector(selectPersonsSearchResult);
   
    const personSearchVisited = useAppSelector(selectPersonsVisited);

    const [canEdit, setCanEdit] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [isReport, setIsReport] = useState(false);

    const initialValues: SearchPersonFilterDto = {
        firstName: "",
        surname: "",
        act: "",
        section: "",
        court: "",
        state: "",
        city: "",
        commissionStatusId: '',
        appointmentStatusId: '',
        isSearchCurrent: true,
        fromDate: '',
        toDate: ''
    }
    // const [getPersonDetails] = useLazyGetPersonDetailsQuery();
    const [searchPersonDetail] = useSearchPersonDetailsMutation();
    useEffect(() => {
        const canEdit = checkFeatureAccess(user, "Edit Appointment");
        setCanEdit(canEdit)
        if (personSearchVisited){
            setIsReport(!personSearchFilter.isSearchCurrent);
            setDirty(true);
        }
        dispatch(saveCurrentBreadcrumbs(breadcrumbs));
    }, [])

    const history = useNavigate();
    const routeChange = () => {
        history('/persondetails/addperson');
    }

    const onSubmit = async (values: SearchPersonFilterDto) => {
        setBusy(true);

        const criteria: PersonSearchCriteriaModelDto = {
            firstName: values.firstName,
            surname: values.surname,
            searchActId: parseInt(values.act) | 0,
            searchSectionId: parseInt(values.section) | 0,
            courtTribunalId: parseInt(values.court) | 0,
            cityId: parseInt(values.city) | 0,
            stateId: parseInt(values.state) | 0,
            appointmentStatusId: parseInt(values.appointmentStatusId) | 0,
            commissionStatusId: parseInt(values.commissionStatusId) | 0,
            isSearchCurrent: values.isSearchCurrent,
            fromDate: values.fromDate,
            toDate: values.toDate
        }
        const persons = await searchPersonDetail(criteria).unwrap();
        dispatch(savePersonSearchFilterAndResult({filter: values, result: persons}));
        setBusy(false);
    }

    const onRemovePerson = async () => {
        var personIdTobeRemoved: any = selectedRows.map((x: any) => x.personId);
        if (personIdTobeRemoved) {
            setBusy(true);
            await removePerson(personIdTobeRemoved).then((result) => {
                if (result != null) {
                    setModalShow(false);
                    setSelectedRows([]);
                    dispatch(personsApi.util.invalidateTags(['AuthorisedPersons']));
                    dispatch(personsApi.util.invalidateTags(['PersonDetails']));
                    const fetchData = async () => {
                        const criteria: PersonSearchCriteriaModelDto = {
                            firstName: personSearchFilter.firstName,
                            surname: personSearchFilter.surname,
                            searchActId: parseInt(personSearchFilter.act) | 0,
                            searchSectionId: parseInt(personSearchFilter.section) | 0,
                            courtTribunalId: parseInt(personSearchFilter.court) | 0,
                            cityId: parseInt(personSearchFilter.city) | 0,
                            stateId: parseInt(personSearchFilter.state) | 0,
                            appointmentStatusId: parseInt(personSearchFilter.appointmentStatusId),
                            commissionStatusId: parseInt(personSearchFilter.commissionStatusId),
                            isSearchCurrent: personSearchFilter.isSearchCurrent,
                        }
                        const persons = await searchPersonDetail(criteria).unwrap();
                        dispatch(savePersonSearchResult(persons));
                    };
                    fetchData();
                }
            }).catch((err: any) => {
                console.log("err -> ", JSON.stringify(err));
                errorToast('Failed to remove person.')
            }).finally(() => {
                setBusy(false);
            });
        }
    }
    return (
            <React.Fragment>
                    <Container fluid="md">
                        <Row>
                            <Col>
                                <Card className='table-card'>
                                    <Card.Header>
                                        <Card.Title as='h5'>Person Details</Card.Title>
                                    </Card.Header>
                                    <Card.Body className='pb-0'>
                                        <PersonSearchFilter handleCancel={() => {
                                            dispatch(resetPersonSearchFilter(initialValues));
                                        }}
                                                            handleSubmit={onSubmit}
                                                            initialValues={personSearchFilter}
                                                            handleIsDirty={
                                                                (dirty: boolean) => {
                                                                    setDirty(dirty);
                                                                }}
                                                            handleIsReport={(isReport: boolean) => {
                                                                setIsReport(isReport);
                                                            }}/>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <Card className='table-card'>
                                    <Card.Header>
                                            <Card.Title as="h5"> {checkFeatureAccess(user, "Add Person") &&<Button variant="outline-info" type="submit"
                                                                         onClick={routeChange}
                                                                         className='mr-1 float-Center'>Add
                                                Person</Button>}
                                                {selectedRows.length === 1 && checkFeatureAccess(user, "Remove Person") &&
                                                    <Button variant="outline-info" type="submit"
                                                            onClick={() => setModalShow(true)}
                                                            className='mr-1 float-right'>Remove
                                                        Person</Button>}
                                                {/*//  <!-- make sure result was search(can use data from reducer, and make sur it a report and search criteria not update after search -->*/}
                                                {
                                                    personSearchVisited && isReport && !isDirty && checkFeatureAccess(user, "Person Detail Export") &&
                                                    <Button variant='outline-info' type="button" onClick={async () => {
                                                        setBusy(true);
                                                        const criteria: PersonSearchCriteriaModelDto = {
                                                            firstName: personSearchFilter.firstName,
                                                            surname: personSearchFilter.surname,
                                                            searchActId: parseInt(personSearchFilter.act) | 0,
                                                            searchSectionId: parseInt(personSearchFilter.section) | 0,
                                                            courtTribunalId: parseInt(personSearchFilter.court) | 0,
                                                            cityId: parseInt(personSearchFilter.city) | 0,
                                                            stateId: parseInt(personSearchFilter.state) | 0,
                                                            appointmentStatusId: parseInt(personSearchFilter.appointmentStatusId) | 0,
                                                            commissionStatusId: parseInt(personSearchFilter.commissionStatusId) | 0,
                                                            isSearchCurrent: personSearchFilter.isSearchCurrent,
                                                            fromDate: personSearchFilter.fromDate,
                                                            toDate: personSearchFilter.toDate
                                                        }
                                                        const responseData = await downloadCommission(criteria);
                                                        const url = window.URL.createObjectURL(new Blob([responseData]));
                                                        const link = document.createElement('a');
                                                        link.href = url;
                                                        var d = new Date();
                                                        var dateStr = d.getFullYear() + ('0' + (d.getMonth() + 1)).slice(-2) +  ('0' + d.getDate()).slice(-2);
                                                        link.setAttribute('download', `RAPWOFExport${dateStr}.csv`);
                                                        document.body.appendChild(link);
                                                        link.click();
                                                        setBusy(false);
                                                    }}
                                                    className='float-right mr-2'>Export Data</Button>}
                                            </Card.Title>

                                    </Card.Header>
                                    {personSearchVisited &&
                                        <Card.Body className='pb-0'>
                                            <div style={{height: '100%'}}>
                                                <PerfectScrollbar>
                                                    <BasicTableControl columns={canEdit ?
                                                        constants.allPersonsColumns
                                                        : constants.allPersonsColumnsToViewOnly}
                                                                       data={personsSerchResult}
                                                                       setSelectedRows={setSelectedRows}
                                                                       renderRowSubComponent={[]}
                                                                       hideActionColumn={[]}
                                                    />
                                                </PerfectScrollbar>
                                            </div>
                                        </Card.Body>
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                    <ConfirmModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        heading="Remove Person"
                        body="Are you sure you want to remove the selected person?"
                        onDelete={onRemovePerson}
                    />
            </React.Fragment>
    );
};
export default PersonSearchView;
